import React, { memo, useState, useCallback, useMemo } from 'react';
import { ComposedChart, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment-timezone';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  getSlashedDateStringWithoutTime,
  formatNumberToDisplayedString,
  formatDateTimeWithoutDivider,
  formatDateTimeWithoutYearsAndSecond,
} from 'shared-modules/services';
import styleConstants from 'shared-modules/styles/_constants.scss';
import styles from './profitLossChart.module.scss';
import './index.scss';

moment.tz.setDefault('Asia/Tokyo');

const xAxisOffsetMultiplier = 0.03;

const CHART_TYPES = {
  AREA: 'Area',
  LINE: 'Line',
};

const axisTickStyle = { fontSize: '10px', fill: styleConstants.WEB_WHITE };
const axisLineStyle = { opacity: 0.8 };
const gridOpacity = 0.8;
const defaultYAxisWidth = 60;
const plChartTemplate = {
  id: 0,
  dataKey: 'pl',
  name: '実現損益',
  chartType: CHART_TYPES.AREA,
  type: 'linear',
  isActive: true,
  fillColor: styleConstants.WEB_PL_CHART_FILL_GREEN,
  strokeColor: styleConstants.WEB_PL_CHART_STROKE_GREEN,
  fillColorId: 'plFill',
  strokeColorId: 'plStroke',
};
const uplChartTemplate = {
  id: 1,
  dataKey: 'upl',
  name: '評価損益',
  chartType: CHART_TYPES.AREA,
  type: 'linear',
  isActive: true,
  fillColor: styleConstants.WEB_PL_CHART_FILL_RED,
  strokeColor: styleConstants.WEB_PL_CHART_STROKE_RED,
  fillColorId: 'uplFill',
  strokeColorId: 'uplStroke',
};
const sumTemplate = {
  id: 2,
  dataKey: 'sum',
  name: '総合損益',
  chartType: CHART_TYPES.LINE,
  type: 'linear',
  isActive: true,
  fillColor: styleConstants.WEB_WHITE,
  strokeColor: styleConstants.WEB_WHITE,
  fillColorId: null,
  strokeColorId: null,
};

const getStrokeColor = (value) => {
  if (value > 0) return styleConstants.WEB_PL_CHART_STROKE_GREEN;
  if (value < 0) return styleConstants.WEB_PL_CHART_STROKE_RED;
  return styleConstants.WEB_WHITE;
};

const ProfitLossChart = ({ data: dataRaw, chartMargin, useDefaultYAxisWidth, yAxisWidth, withTimeLabels, scale }) => {
  const dataIsEmpty = useMemo(() => !dataRaw.length, [dataRaw]);
  const data = useMemo(() => dataRaw.map((item) => ({ ...item, date: moment(item.date).valueOf() })), [dataRaw]);

  const hasPlData = useMemo(() => data.some((obj) => Object.keys(obj).indexOf('pl') !== -1), [data]);
  const hasUplData = useMemo(() => data.some((obj) => Object.keys(obj).indexOf('upl') !== -1), [data]);
  const hasSumData = useMemo(() => data.some((obj) => Object.keys(obj).indexOf('sum') !== -1), [data]);

  const [plChartIsActive, setPlChartActivity] = useState(hasPlData);
  const [uplChartIsActive, setUplChartActivity] = useState(hasUplData);
  const [sumChartIsActive, setSumChartActivity] = useState(hasSumData);

  const changePlChartActivity = useCallback(() => setPlChartActivity((prevState) => !prevState), [setPlChartActivity]);
  const changeUplChartActivity = useCallback(
    () => setUplChartActivity((prevState) => !prevState),
    [setUplChartActivity],
  );
  const changeSumChartActivity = useCallback(
    () => setSumChartActivity((prevState) => !prevState),
    [setSumChartActivity],
  );

  const timeFormatter = useCallback(
    (value) => (withTimeLabels ? formatDateTimeWithoutDivider(value) : getSlashedDateStringWithoutTime(value)),
    [withTimeLabels],
  );

  const xAxisTimeFormatter = useCallback(
    (value) => (withTimeLabels ? formatDateTimeWithoutYearsAndSecond(value) : getSlashedDateStringWithoutTime(value)),
    [withTimeLabels],
  );

  const renderLegend = useCallback(
    () => (
      <div className={styles.legendWrapper}>
        {hasPlData && (
          <div
            aria-hidden
            key={plChartTemplate.id}
            role="button"
            onClick={changePlChartActivity}
            className={classNames(styles.labelWrapper, { [styles.isDisabled]: !plChartIsActive })}
          >
            <div
              className={classNames(styles.area, { [styles.isDisabled]: !plChartIsActive })}
              style={{ backgroundColor: plChartTemplate.strokeColor }}
            />
            <span>{plChartTemplate.name}</span>
          </div>
        )}
        {hasUplData && (
          <div
            aria-hidden
            key={uplChartTemplate.id}
            role="button"
            onClick={changeUplChartActivity}
            className={classNames(styles.labelWrapper, { [styles.isDisabled]: !uplChartIsActive })}
          >
            <div
              className={classNames(styles.area, { [styles.isDisabled]: !uplChartIsActive })}
              style={{ backgroundColor: uplChartTemplate.strokeColor }}
            />
            <span>{uplChartTemplate.name}</span>
          </div>
        )}
        {hasSumData && (
          <div
            aria-hidden
            key={sumTemplate.id}
            role="button"
            onClick={changeSumChartActivity}
            className={classNames(styles.labelWrapper, { [styles.isDisabled]: !sumChartIsActive })}
          >
            <div
              className={classNames(styles.line, { [styles.isDisabled]: !sumChartIsActive })}
              style={{ backgroundColor: sumTemplate.strokeColor }}
            />
            <span>{sumTemplate.name}</span>
          </div>
        )}
      </div>
    ),
    [
      changePlChartActivity,
      changeUplChartActivity,
      changeSumChartActivity,
      plChartIsActive,
      uplChartIsActive,
      sumChartIsActive,
      hasPlData,
      hasUplData,
      hasSumData,
    ],
  );
  const renderTooltip = useCallback(
    ({ label, payload }) => (
      <div className={styles.tooltipWrapper}>
        <p className={styles.tooltipLabel}>{timeFormatter(label)}</p>
        {payload?.map(({ name, value }) => (
          <p
            key={name}
            className={styles.tooltipItem}
            style={{ color: name === sumTemplate.name ? styleConstants.WEB_WHITE : getStrokeColor(value) }}
          >
            {`${name} : ${formatNumberToDisplayedString({ value, withoutPlus: true })}`}
          </p>
        ))}
      </div>
    ),
    [timeFormatter],
  );

  const getOffset = useCallback(
    (key) => {
      const mappedArray = data.reduce((totalArr, item) => {
        if (item[key]) totalArr.push(item[key]);
        return totalArr;
      }, []);
      const dataMax = Math.max(...mappedArray);
      const dataMin = Math.min(...mappedArray);

      if (dataMax <= 0) {
        return 0;
      }
      if (dataMin >= 0) {
        return 1;
      }

      return dataMax / (dataMax - dataMin);
    },
    [data],
  );

  const plOffset = useMemo(() => getOffset(plChartTemplate.dataKey), [getOffset]);
  const uplOffset = useMemo(() => getOffset(uplChartTemplate.dataKey), [getOffset]);

  const domain = useMemo(() => {
    if (!data.length) {
      return ['dataMin', 'dataMax'];
    }

    const duration = data[data.length - 1].date - data[0].date;

    return ['dataMin', `dataMax + ${duration * xAxisOffsetMultiplier}`];
  }, [data]);

  if (dataIsEmpty) {
    return <div className={styles.emptyWrapper}>実現損益が発生するとグラフが描画されます。</div>;
  }
  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={100}>
      <ComposedChart data={data} margin={chartMargin}>
        <CartesianGrid stroke={styleConstants.WEB_PL_GRID_COLOR} opacity={gridOpacity} />
        {scale === 'auto' && (
          <XAxis
            dataKey="date"
            tickFormatter={xAxisTimeFormatter}
            tick={axisTickStyle}
            axisLine={axisLineStyle}
            scale={scale}
            type="number"
            domain={domain}
            interval="preserveStartEnd"
          />
        )}
        {scale !== 'auto' && (
          <XAxis
            dataKey="date"
            tickFormatter={xAxisTimeFormatter}
            tick={axisTickStyle}
            axisLine={axisLineStyle}
            scale={scale}
          />
        )}

        <YAxis tick={axisTickStyle} width={useDefaultYAxisWidth ? defaultYAxisWidth : yAxisWidth} />
        <defs>
          <linearGradient id={plChartTemplate.fillColorId} x1="0" y1="0" x2="0" y2="1">
            <stop offset={plOffset} stopColor={styleConstants.WEB_PL_CHART_FILL_GREEN} stopOpacity={1} />
            <stop offset={plOffset} stopColor={styleConstants.WEB_PL_CHART_FILL_RED} stopOpacity={1} />
          </linearGradient>
          <linearGradient id={plChartTemplate.strokeColorId} x1="0" y1="0" x2="0" y2="1">
            <stop offset={plOffset} stopColor={styleConstants.WEB_PL_CHART_STROKE_GREEN} stopOpacity={1} />
            <stop offset={plOffset} stopColor={styleConstants.WEB_PL_CHART_STROKE_RED} stopOpacity={1} />
          </linearGradient>
          <linearGradient id={uplChartTemplate.fillColorId} x1="0" y1="0" x2="0" y2="1">
            <stop offset={uplOffset} stopColor={styleConstants.WEB_PL_CHART_FILL_GREEN} stopOpacity={1} />
            <stop offset={uplOffset} stopColor={styleConstants.WEB_PL_CHART_FILL_RED} stopOpacity={1} />
          </linearGradient>
          <linearGradient id={uplChartTemplate.strokeColorId} x1="0" y1="0" x2="0" y2="1">
            <stop offset={uplOffset} stopColor={styleConstants.WEB_PL_CHART_STROKE_GREEN} stopOpacity={1} />
            <stop offset={uplOffset} stopColor={styleConstants.WEB_PL_CHART_STROKE_RED} stopOpacity={1} />
          </linearGradient>
        </defs>
        {plChartIsActive && (
          <Area
            key={plChartTemplate.id}
            type="linear"
            dataKey={plChartTemplate.dataKey}
            name={plChartTemplate.name}
            fill={`url(#${plChartTemplate.fillColorId})`}
            stroke={`url(#${plChartTemplate.strokeColorId})`}
          />
        )}
        {uplChartIsActive && (
          <Area
            key={uplChartTemplate.id}
            type="linear"
            dataKey={uplChartTemplate.dataKey}
            name={uplChartTemplate.name}
            fill={`url(#${uplChartTemplate.fillColorId}`}
            stroke={`url(#${uplChartTemplate.strokeColorId})`}
          />
        )}
        {sumChartIsActive && (
          <Line
            key={sumTemplate.id}
            dot={false}
            type="linear"
            dataKey={sumTemplate.dataKey}
            name={sumTemplate.name}
            fill={sumTemplate.fillColor}
            stroke={sumTemplate.strokeColor}
          />
        )}
        <Tooltip content={renderTooltip} />
        <Legend content={renderLegend} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

ProfitLossChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      pl: PropTypes.number,
      upl: PropTypes.number,
      sum: PropTypes.number,
    }),
  ),
  chartMargin: PropTypes.shape({
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    right: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
  }),
  useDefaultYAxisWidth: PropTypes.bool,
  yAxisWidth: PropTypes.number,
  withTimeLabels: PropTypes.bool,
  scale: PropTypes.oneOf(['auto', 'linear', 'pow', 'sqrt', 'log', 'identity', 'time', 'band', 'point', 'ordinal']),
};

ProfitLossChart.defaultProps = {
  data: [],
  chartMargin: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  useDefaultYAxisWidth: false,
  yAxisWidth: 25,
  withTimeLabels: false,
  scale: 'auto',
};

export default memo(ProfitLossChart);
