import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Decimal from 'decimal.js';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { COMPREHENSIVE_EVALUATION_LABEL, LAB_STATUS_MAP } from 'shared-modules/constants';
import {
  SHARE_CANCEL_ACTION,
  SHARE_CANCEL_CONFIRMATION,
  SHARE_CANCEL_TITLE,
  SHARE_STOP_ACTION,
  SHARE_STOP_CONFIRMATION,
  SHARE_STOP_TITLE,
} from 'shared-modules/constants/builder';
import { formatNumberToPercentageString } from 'shared-modules/services';
import { cancelLabRequest, getAllLabsRequest, resetForm } from 'shared-modules/redux/labs';
import { numberExists, roundRoi, roundUpBy1000, nop } from 'shared-modules/utils';
import { useChangeService } from 'shared-modules/services/hooks';
import { getLabImageSrc } from '../../../../../../services';
import { Card } from '../Card';
import { useKeyDownHandler } from '../../../../../../services/hooks';
import { useStartShareButton } from '../../hooks';
import { ServiceDisabledInfo } from '../../../../../common';
import { Evaluation, FeatureTags } from '../../../../../../components';
import { openConfirmationModal } from '../../../../../../redux/actions';
import styles from './strategy.module.scss';

const getSign = (number) => (number >= 0 ? '+' : '');
const getColorClass = (number) => (number >= 0 ? 'plus' : 'minus');
const getRankingDiffStr = (number) => (number >= 0 ? `${number}位up↑` : `${0 - number}位down↓`);

export const Strategy = memo(({ lab, simulationData, onClick, serviceId, hasSimulationData }) => {
  const {
    id,
    comment,
    name,
    status,
    image,
    instanceCount,
    oldInstanceCount,
    roiRanking,
    oldRoiRanking,
    reason,
    style: styleValue,
    technicalTerm,
    attribute,
  } = lab;
  const { comprehensiveEvaluation = 0 } = attribute ?? {};
  const { simulationStats } = simulationData ?? {};
  const { realizedPl, unrealizedPl, marginRecommended } = simulationStats ?? {};

  const totalPl = useMemo(
    () =>
      numberExists(realizedPl) && numberExists(unrealizedPl) ? new Decimal(realizedPl).add(unrealizedPl).toNumber() : 0,
    [realizedPl, unrealizedPl],
  );

  const calculatedMarginRecommended = useMemo(
    () => (marginRecommended ? roundUpBy1000(marginRecommended) : 0),
    [marginRecommended],
  );

  const roi = useMemo(
    () => new Decimal(totalPl).div(calculatedMarginRecommended).mul(100).mul(1).toNumber(),
    [totalPl, calculatedMarginRecommended],
  );

  const calculatedRoi = useMemo(() => roundRoi(roi), [roi]);
  const instanceDiff = instanceCount - (oldInstanceCount || 0);
  const rankingDiff = roiRanking && oldRoiRanking ? oldRoiRanking - roiRanking : 0;
  const labStatus = LAB_STATUS_MAP[String(status)];
  const button = useStartShareButton(serviceId, 'はじめる');

  const dispatch = useDispatch();
  const isJudging = labStatus === 'シェア審査中';
  const isPublishing = labStatus === 'シェア中';
  const isPublish = isJudging || isPublishing;

  const handleClick = useCallback(() => {
    dispatch(
      cancelLabRequest({
        labId: id,
        callback: () => {
          dispatch(getAllLabsRequest());
          dispatch(resetForm());
        },
      }),
    );
  }, [dispatch, id]);

  const handleOpenConfirmationModal = useCallback(() => {
    dispatch(
      openConfirmationModal({
        title: isPublishing ? SHARE_STOP_TITLE : SHARE_CANCEL_TITLE,
        callback: handleClick,
        bodyText: isPublishing ? SHARE_STOP_CONFIRMATION : SHARE_CANCEL_CONFIRMATION,
        buttonBackText: '戻る',
        buttonNextText: 'OK',
        successButtonIsGreen: true,
      }),
    );
  }, [dispatch, isPublishing, handleClick]);

  const handleKeyDown = useKeyDownHandler(handleOpenConfirmationModal);

  const link = useMemo(() => {
    const label = isPublishing ? SHARE_STOP_ACTION : SHARE_CANCEL_ACTION;
    return (
      <div
        role="button"
        tabIndex={0}
        className={styles.link}
        onClick={(e) => {
          handleOpenConfirmationModal();
          e.stopPropagation();
        }}
        onKeyDown={handleKeyDown}
      >
        {label}
      </div>
    );
  }, [handleOpenConfirmationModal, handleKeyDown, isPublishing]);

  const BuilderShareCard = () => {
    return isPublish ? (
      <div className={styles.content}>
        <div style={{ fontSize: 12, marginLeft: 54 }}>
          <div className="d-flex mb-2">
            <FeatureTags styleValue={styleValue} periodValue={String(technicalTerm)} />
          </div>
          <div className="d-flex justify-content-between">
            <div className={classNames(styles.media, 'me-1')}>
              <div className={styles.body}>
                <p className={styles.label}>{COMPREHENSIVE_EVALUATION_LABEL}</p>
                {hasSimulationData ? (
                  <Evaluation value={comprehensiveEvaluation} className={styles.riskHeight} />
                ) : (
                  <p className={styles.calculating}>計算中</p>
                )}
              </div>
            </div>
            <div className={classNames(styles.media, 'me-1')}>
              <div className={styles.body}>
                <p className={styles.label}>収益率</p>
                {hasSimulationData ? (
                  <p className={classNames(getColorClass(roi), styles.roi)}>
                    {formatNumberToPercentageString(calculatedRoi)}
                  </p>
                ) : (
                  <p className={styles.calculating}>計算中</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.separator, 'mb-3')} />
        <div className={styles.bodyTitle}>あなたの実績</div>
        <div className="grid grid--1x2">
          <div className="rank-block">
            <div className="material-icons rank-block__icon">how_to_reg</div>
            <div className="text-end">
              <div>稼働数（前週比）</div>
              <div className={instanceDiff && getColorClass(instanceDiff)}>
                <span className="rank-block__value">{instanceCount}</span>人 ({' '}
                {instanceCount && oldInstanceCount && instanceCount !== oldInstanceCount
                  ? `${getSign(instanceDiff)}${instanceDiff}`
                  : '-'}{' '}
                )
              </div>
            </div>
          </div>
          <div className="rank-block">
            <div className="material-icons rank-block__icon">stars</div>
            <div className="text-end">
              <div>収益ランク（前週比）</div>
              <div className={rankingDiff && getColorClass(rankingDiff)}>
                <span className="rank-block__value">{roiRanking || '-'}</span>位（{' '}
                {roiRanking && oldRoiRanking && roiRanking !== oldRoiRanking ? getRankingDiffStr(rankingDiff) : '-'} ）
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer}>{link}</div>
      </div>
    ) : (
      <div className={styles.content}>
        <div className={classNames(styles.separator, 'mb-3')} />
        <div className={styles.bodyTitle}>{labStatus} 理由</div>
        <div className={styles.nonAdmmitedBlock}>{reason}</div>
        <div className={styles.buttonArea}>{button}</div>
      </div>
    );
  };

  const { onChange } = useChangeService();
  const handleClickPanel = useCallback(() => {
    onChange(serviceId);
    onClick();
  }, [onClick, onChange, serviceId]);
  const handleOnClickPanel = isPublish ? handleClickPanel : undefined;
  const handleKeyDownPanel = useKeyDownHandler(isPublish ? handleClickPanel : nop);

  return (
    <Card>
      <div
        role="button"
        tabIndex={0}
        className={classNames(styles.container, { [styles.pointer]: !isPublish })}
        onClick={handleOnClickPanel}
        onKeyDown={isPublish ? handleKeyDownPanel : undefined}
      >
        <ServiceDisabledInfo serviceId={serviceId}>
          <>
            <div className={styles.header}>
              <img src={getLabImageSrc(image)} className={styles.icon} alt="" />
              <div style={{ width: '301px' }}>
                <div className={classNames(styles.title, 'one-line')}>{name}</div>
                <p className={classNames(styles.description, 'one-line')}>{comment}</p>
              </div>
            </div>
            <div className={styles.body}>{BuilderShareCard()}</div>
          </>
        </ServiceDisabledInfo>
      </div>
    </Card>
  );
});

Strategy.propTypes = {
  lab: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
    instanceCount: PropTypes.number,
    oldInstanceCount: PropTypes.number,
    roiRanking: PropTypes.number,
    oldRoiRanking: PropTypes.number,
    image: PropTypes.shape({
      isPreset: PropTypes.bool.isRequired,
      filename: PropTypes.string.isRequired,
      content: PropTypes.string,
    }).isRequired,
    style: PropTypes.string,
    technicalTerm: PropTypes.string,
    comment: PropTypes.string.isRequired,
    roi: PropTypes.number.isRequired,
    reason: PropTypes.string,
    attribute: PropTypes.shape({
      comprehensiveEvaluation: PropTypes.number,
    }),
  }).isRequired,
  simulationData: PropTypes.shape({}),
  onClick: PropTypes.func.isRequired,
  serviceId: PropTypes.string.isRequired,
  hasSimulationData: PropTypes.bool,
};

Strategy.defaultProps = {
  hasSimulationData: true,
  simulationData: undefined,
};
