import Decimal from 'decimal.js';
import { isArray as _isArray, isEqual as _isEqual } from 'lodash';
import { IMS_SYSTEM_KBN_CFD, IMS_SYSTEM_KBN_ETF, IMS_SYSTEM_KBN_FX, LOSSCUT_STATUSES } from '../constants/apiConstant';
import { ALL, ALL_SERVICES, SERVICE_ORDERS, SERVICE_VALUE_TO_ID } from '../constants/core';
import {
  ADDITIONAL_INSTRUMENT_IDS,
  BALANCE_METER_ATTENTION,
  BUY_SELL_MAIN,
  CFD,
  COUNTRY_TYPE,
  ETF,
  FX,
  MIXED,
  NOT_PAIRED_YEN_PIP_MULTIPLIER,
  PAIRED_YEN_PIP_MULTIPLIER,
  SL_SETTING_ALL,
  SL_SETTING_NO,
  SL_SETTING_SOME,
  AP_SUFFIX,
  M_SUFFIX,
  LESS_TRADING_THRESHOLD,
} from '../constants';
import { BUILDER_DEFAULT_QUANTITIES, BUILDER_QUANTITY_MAPS } from '../constants/builder';

export {
  calcMaxDdAssessment,
  calcRoiAssessment,
  calcPriceDiffAssessment,
  calcTotalPriceDiffAssessment,
  calcCloseTradeCountAssessment,
  calcComprehensiveEvaluation,
  calcComprehensiveEvaluationByAttibute,
  isReadySimulationResults,
  includeComprehensiveEvaluation,
} from './comprehensiveEvaluation';

export const DECIMAL_ZERO = new Decimal('0');
const DECIMAL_ONE = new Decimal('1');

export const isArray = _isArray;
export const isEqual = _isEqual;

export const selectByServiceId = (serviceId, ...values) => {
  const index = ALL_SERVICES.findIndex((service) => service === serviceId);
  if (index < 0 || index >= values.length) {
    return null;
  }
  return values[index];
};

export const numberExists = (val) => {
  if (val == null || val === '') {
    return false;
  }
  return Number.isFinite(Number(val));
};

export const nop = () => {};

export const valueToPercent = (value, min, max) => ((value - min) * 100) / (max - min);

const getRate = (serviceId, rates, instrumentId, accountInfo) => {
  const info = accountInfo?.[serviceId];
  if (info == null) {
    return null;
  }
  if (!info.isNotAvailable) {
    return rates?.[instrumentId];
  }
  return null;
};

export const getEodRate = (rate) => {
  if (rate == null) {
    return null;
  }
  const { askClose, bidClose, dateClose } = rate;
  if (askClose == null || bidClose == null) {
    return null;
  }
  return { ask: new Decimal(String(askClose)), bid: new Decimal(String(bidClose)), date: dateClose };
};

const calculateMidRate = (rate) => {
  if (rate == null) {
    return null;
  }
  const { ask, bid } = rate;
  if (ask == null || bid == null) {
    return null;
  }
  return new Decimal(ask).add(bid).div(2);
};

export const roundRoi = (roi) => {
  if (!Number.isFinite(roi)) {
    return null;
  }
  const decRoi = new Decimal(roi);
  if (decRoi.isNegative()) {
    return decRoi.floor().toNumber();
  }
  return decRoi.ceil().toNumber();
};

export const roundUpBy1000 = (num) => {
  if (num == null) {
    return 0;
  }
  return new Decimal(num).div(1000).ceil().mul(1000).toNumber();
};

const getPipConversion = (serviceId, instrumentId) => {
  if (serviceId == null || serviceId === '' || instrumentId == null || instrumentId === '') {
    return null;
  }
  if (serviceId !== FX) {
    return DECIMAL_ONE;
  }
  if (instrumentId.endsWith(COUNTRY_TYPE.JPY)) {
    return new Decimal(String(PAIRED_YEN_PIP_MULTIPLIER));
  }
  return new Decimal(String(NOT_PAIRED_YEN_PIP_MULTIPLIER));
};

export const getRateConversion = ({ instrumentId, instrumentList, serviceId, rates, accountInfo, isEod }) => {
  if (instrumentId == null || instrumentId.length === 0) {
    return null;
  }
  let conversionInstrumentId = instrumentList?.[instrumentId]?.conversionInstrumentId;
  if (conversionInstrumentId == null) {
    // 円転レートでない銘柄
    return DECIMAL_ONE;
  }
  // 現状FX以外のアセットの円転銘柄はストアにetf.USD/JPYのように入っているため、
  // FX以外のconversionInstrumentIdにserviceIdを付与する
  if (serviceId !== FX) {
    conversionInstrumentId = `${serviceId}.${conversionInstrumentId}`;
  }
  let rate = getRate(serviceId, rates, conversionInstrumentId, accountInfo);
  if (isEod) {
    rate = getEodRate(rate);
  }
  // 口座がないかメンテナンス中かレートが取れなかった場合は null
  return calculateMidRate(rate);
};

const getPricePrecision = (instrument) => {
  if (instrument == null) {
    return null;
  }
  const { pricePrecision } = instrument;
  if (pricePrecision == null) {
    return null;
  }
  return new Decimal(String(instrument.pricePrecision));
};

const getSigma = (instrument) => {
  if (instrument == null) {
    return null;
  }
  const { sigma } = instrument;
  if (sigma == null) {
    return null;
  }
  return new Decimal(String(instrument.sigma));
};

export const composeInstrumentKey = (serviceId, instrumentId) => `${serviceId}_${instrumentId}`;

export const decomposeInstrumentKey = (instrumentKey) => {
  const [serviceId, ...tokens] = instrumentKey.split('_');
  const instrumentId = tokens.join('_');
  return [serviceId, instrumentId];
};

export const groupByInstrumentKey = (strategyList) => {
  return strategyList.reduce((map, strategy) => {
    const { strategyDetail } = strategy;
    const { serviceId, instrumentId } = strategyDetail;
    const key = composeInstrumentKey(serviceId, instrumentId);
    const strategies = map[key] ?? [];
    strategies.push(strategy);
    return { ...map, [key]: strategies };
  }, {});
};

export const makeInstrumentInfo = ({ serviceId, instrumentId, instrumentList, rates, accountInfo }) => {
  const rate = getRate(serviceId, rates, instrumentId, accountInfo);
  const eodRate = getEodRate(rate);
  if (eodRate == null) {
    return null;
  }
  const pipConversion = getPipConversion(serviceId, instrumentId);
  if (pipConversion == null) {
    return null;
  }
  const instrument = instrumentList?.[instrumentId];
  const sigma = getSigma(instrument);
  if (sigma == null) {
    return null;
  }
  const pricePrecision = getPricePrecision(instrument);
  if (pricePrecision == null) {
    return null;
  }
  const rateConversion = getRateConversion({
    instrumentId,
    instrumentList,
    serviceId,
    rates,
    accountInfo,
    isEod: true,
  });
  if (rateConversion == null) {
    return null;
  }
  return {
    eodRate,
    rateConversion,
    pipConversion,
    pricePrecision,
    sigma,
  };
};

export const makeInstrumentInfoMap = ({ groupedByInstrumentKey, instrumentList, rates, accountInfo }) => {
  return Object.keys(groupedByInstrumentKey).reduce((map, key) => {
    const [serviceId, instrumentId] = decomposeInstrumentKey(key);
    const info = makeInstrumentInfo({ serviceId, instrumentId, instrumentList, rates, accountInfo });
    if (info != null) {
      return { ...map, [key]: info };
    }
    return map;
  }, {});
};

// ±2σ到達時の想定PLを算出します。
export const calculateAssumptionPl = (order, instrumentInfo) => {
  const { eodRate, sigma, pipConversion, rateConversion } = instrumentInfo;
  const evalRate = eodRate.ask;
  const twoSigma = sigma.mul(2);
  const minusTwoSigmaRate = evalRate.sub(twoSigma);
  const plusTwoSigmaRate = evalRate.add(twoSigma);
  const { entryPrice1, tp, side, quantity, set } = order;
  const entryPrice = new Decimal(entryPrice1);
  const takeProfit = new Decimal(tp).mul(pipConversion);
  let minusAssumptionPl = DECIMAL_ZERO;
  if (evalRate.comparedTo(entryPrice) >= 0 && entryPrice.sub(takeProfit).comparedTo(minusTwoSigmaRate) >= 0) {
    let tempPl;
    if (String(BUY_SELL_MAIN.BUY.ID) === String(side)) {
      tempPl = minusTwoSigmaRate.sub(entryPrice).abs().negated();
    } else {
      tempPl = takeProfit;
    }
    minusAssumptionPl = tempPl.mul(quantity).mul(set).mul(rateConversion);
  }

  let plusAssumptionPl = DECIMAL_ZERO;
  if (evalRate.comparedTo(entryPrice) <= 0 && entryPrice.add(takeProfit).comparedTo(plusTwoSigmaRate) <= 0) {
    let tempPl;
    if (String(BUY_SELL_MAIN.SELL.ID) === String(side)) {
      tempPl = plusTwoSigmaRate.sub(entryPrice).abs().negated();
    } else {
      tempPl = takeProfit;
    }
    plusAssumptionPl = tempPl.mul(quantity).mul(set).mul(rateConversion);
  }
  return [minusAssumptionPl, plusAssumptionPl];
};

// リスク評価値 = (推奨証拠金 + ±σ到達時の想定PL) ÷ 推奨証拠金 - 1
export const calculateRiskAssessment = (marginRecommended, assumptionPl) => {
  return marginRecommended.add(assumptionPl).div(marginRecommended).toDP(16, Decimal.ROUND_FLOOR).sub(DECIMAL_ONE);
};

const PERCENT_MINUS_10 = new Decimal('-0.1');
const PERCENT_MINUS_20 = new Decimal('-0.2');
const PERCENT_MINUS_30 = new Decimal('-0.3');
const PERCENT_MINUS_40 = new Decimal('-0.4');

/**
 * 0%: 評価なし
 * 0%未満 〜 -10%: 5
 * -10% 〜 -20%: 4
 * -20% 〜 -30%: 3
 * -30% 〜 -40%: 2
 * -40% 〜: 1
 */
export const toRiskAssessmentRate = (value) => {
  if (value.comparedTo(DECIMAL_ZERO) < 0 && value.comparedTo(PERCENT_MINUS_10) >= 0) {
    return 5;
  }
  if (value.comparedTo(PERCENT_MINUS_10) < 0 && value.comparedTo(PERCENT_MINUS_20) >= 0) {
    return 4;
  }
  if (value.comparedTo(PERCENT_MINUS_20) < 0 && value.comparedTo(PERCENT_MINUS_30) >= 0) {
    return 3;
  }
  if (value.comparedTo(PERCENT_MINUS_30) < 0 && value.comparedTo(PERCENT_MINUS_40) >= 0) {
    return 2;
  }
  if (value.comparedTo(PERCENT_MINUS_40) < 0) {
    return 1;
  }
  // value >= 0
  return null;
};

export const getSlLabel = (slSetting) => {
  switch (slSetting) {
    case SL_SETTING_ALL:
      return '設定あり';
    case SL_SETTING_SOME:
      return '一部設定あり';
    case SL_SETTING_NO:
      return '設定なし';
    default:
      return '-';
  }
};

export const isLessTrading = (closeTradeCount) => closeTradeCount < LESS_TRADING_THRESHOLD;

const SERVICE_LAST_ORDER = Object.keys(SERVICE_ORDERS).length;

export const compareServiceId = (a, b) => {
  if (a === b) {
    return 0;
  }
  const aOrder = SERVICE_ORDERS[a] ?? SERVICE_LAST_ORDER;
  const bOrder = SERVICE_ORDERS[b] ?? SERVICE_LAST_ORDER;
  if (aOrder < bOrder) {
    return -1;
  }
  return 1;
};

export const makeAccountInfo = ({ maintenanceState, accountState, loginValues, withMarginGroupId }) => {
  const info = ALL_SERVICES.reduce((acc, serviceId) => {
    const isMaintenance = maintenanceState[serviceId]?.isMaintenance === true;
    const { losscutStatus, marginGroupId } = accountState[serviceId] || {};
    const isLossCut = losscutStatus === LOSSCUT_STATUSES.START.ID;
    const accountId = loginValues[`${serviceId}Account`];
    const notExistAccount = accountId == null || accountId === '';
    const notExist = serviceId === FX ? false : notExistAccount;
    return {
      ...acc,
      [serviceId]: {
        isMaintenance,
        isLossCut,
        notExist,
        isNotAvailable: isMaintenance || isLossCut || notExist,
        marginGroupId: withMarginGroupId === true ? marginGroupId : null,
      },
    };
  }, {});
  const all = {
    isMaintenance: ALL_SERVICES.every((serviceId) => info[serviceId]?.isMaintenance),
    isLossCut: ALL_SERVICES.every((serviceId) => info[serviceId]?.isLossCut),
    notExist: ALL_SERVICES.every((serviceId) => info[serviceId]?.notExist),
    isNotAvailable: ALL_SERVICES.every((serviceId) => info[serviceId]?.isNotAvailable),
    marginGroupId: null,
  };
  return {
    ...info,
    [MIXED]: all,
    [ALL]: all,
  };
};

export const getRangeOutKeySet = (rangeOutState) => {
  return (rangeOutState ?? []).reduce((keySet, info) => {
    return Object.keys(info || {}).reduce((ks, sv) => {
      const l = info[sv];
      if (l?.length) {
        l.forEach(({ id }) => ks.add(`${SERVICE_VALUE_TO_ID[sv]}_${id}`));
      }
      return ks;
    }, keySet);
  }, new Set());
};

export const makeParentKey = (item) => {
  if (item == null) {
    return null;
  }
  const { parentId, sourceType, operationId } = item;
  if (!parentId) {
    return null;
  }
  return `${parentId}|${sourceType}|${operationId ?? '_'}`;
};

// Decimal も評価可能
export const isDisplayedNumber = (strOrNum) => Number.isFinite(Number(strOrNum));

export const isShowDepositTransferLink = (level) =>
  level === BALANCE_METER_ATTENTION.warning.level || level === BALANCE_METER_ATTENTION.danger.level;

export const getInstrumentShortName = (instrumentId, instrumentList) => {
  const instrument = instrumentList?.[instrumentId];
  if (instrument?.serviceId !== FX) {
    return instrument?.shortName ?? '-';
  }
  return instrumentId ?? '-';
};

export const getInstrumentSymbol = (instrumentId, instrumentList) => {
  const instrument = instrumentList?.[instrumentId];
  return instrument?.symbol ?? '-';
};

export const getServiceId = (instrumentId, instrumentList) => {
  return instrumentList?.[instrumentId]?.serviceId;
};

export const getRealInstrumentId = (instrumentId) => {
  if (!instrumentId) {
    return instrumentId;
  }
  const index = ALL_SERVICES.findIndex((service) => instrumentId.startsWith(service));
  if (index < 0) {
    return instrumentId;
  }
  return instrumentId.substr(ALL_SERVICES[index].length + 1, instrumentId.length);
};

export const isYenConversionInstrument = (instrumentId, serviceId) => {
  return ADDITIONAL_INSTRUMENT_IDS[serviceId]?.[instrumentId] != null;
};

export const getService = (instrumentId, instrumentList) => {
  return instrumentList?.[getRealInstrumentId(instrumentId)]?.serviceId;
};

export const getBuilderDefaultAmount = (serviceId, instrumentId) => {
  return BUILDER_QUANTITY_MAPS[serviceId][instrumentId] ?? BUILDER_DEFAULT_QUANTITIES[serviceId];
};

export const getSystemKbnByServiceId = (serviceId) => {
  if (serviceId === FX) {
    return IMS_SYSTEM_KBN_FX;
  }
  if (serviceId === ETF) {
    return IMS_SYSTEM_KBN_ETF;
  }
  if (serviceId === CFD) {
    return IMS_SYSTEM_KBN_CFD;
  }
  return null;
};

export const getServiceName = (serviceId) => {
  if (serviceId == null) {
    return null;
  }
  return serviceId.toUpperCase();
};

export const getBuilderUnit = (serviceId) => {
  if (serviceId === FX) {
    return '0.1（万）＝1,000通貨';
  }
  if (serviceId === ETF) {
    return '1口';
  }
  if (serviceId === CFD) {
    return '0.1Lot';
  }
  return null;
};

export const makeMaintenanceQueryString = (accountInfo) => {
  return ALL_SERVICES.map((service) => {
    const { isMaintenance } = accountInfo[service];
    const serviceName = getServiceName(service);
    return `is${serviceName}Maintenance=${isMaintenance}`;
  }).join('&&');
};

export const makeAccountExistQueryString = (accountInfo) => {
  return ALL_SERVICES.filter((service) => service !== FX)
    .map((service) => {
      const { notExist } = accountInfo[service];
      const serviceName = getServiceName(service);
      return `is${serviceName}AccountExist=${!notExist}`;
    })
    .join('&&');
};

export const getSpreadPrecision = (instrumentId, instrumentList) => {
  // FX 銘柄以外に、各サービスの円転用銘柄の場合も FX と判定させる
  if (instrumentList?.[getRealInstrumentId(instrumentId)]?.serviceId === FX) {
    return null;
  }
  // pricePrecision は、銘柄が属しているサービスのものを利用する
  return instrumentList?.[instrumentId]?.pricePrecision;
};

export const getCurrencyUnit = (instrumentId) => {
  if (instrumentId && instrumentId.endsWith(COUNTRY_TYPE.JPY)) return '円';
  if (instrumentId && instrumentId.endsWith(COUNTRY_TYPE.USD)) return 'ドル';
  return '';
};

export const getValidationCurrencyUnitByServiceId = (instrumentId, serviceId) => {
  if (serviceId === CFD) {
    return '';
  }
  return getCurrencyUnit(instrumentId);
};

export const getPipsLabel = (serviceId, instrumentId) => {
  if (serviceId === FX) {
    return 'pips';
  }
  if (serviceId === ETF) {
    return getCurrencyUnit(instrumentId);
  }
  return '';
};

export const getPipsLabelWithParentheses = (serviceId, instrumentId) => {
  const pipsLabel = getPipsLabel(serviceId, instrumentId);
  if (pipsLabel) {
    return `(${pipsLabel})`;
  }
  return pipsLabel;
};

export const getCurrencyUnitByServiceId = (serviceId, instrumentId) => {
  if (serviceId === CFD) {
    return '';
  }
  if (serviceId === FX) {
    return 'pips';
  }
  if (serviceId === ETF) {
    return getCurrencyUnit(instrumentId);
  }
  return '';
};

export const checkCartStrategiesType = (cartItems, currentServiceId) => {
  const uniqueStrategyServices = [...new Set(cartItems.map(({ strategyDetail }) => strategyDetail.serviceId))];
  const newServiceId = uniqueStrategyServices.length === 1 ? uniqueStrategyServices[0] : null;

  return ALL_SERVICES.includes(newServiceId) ? newServiceId : currentServiceId;
};

const strategyListNotAuthorized = (strategyList, accountInfo) => {
  return ALL_SERVICES.some((service) => {
    const hasProduct = (strategyList ?? []).some((strategy) => {
      const { strategyDetail } = strategy;
      const { serviceId } = strategyDetail ?? strategy;
      return serviceId === service;
    });
    return hasProduct && accountInfo[service].isNotAvailable;
  });
};

const getMaxPrice = (price1, price2) => {
  if (price2 == null) {
    return price1;
  }
  return Number(price1) >= Number(price2) ? price1 : price2;
};

const getSettingsBuySellMaxQuantity = (itemList, margin, strategySets) => {
  let buySum = new Decimal(0);
  let sellSum = new Decimal(0);

  for (let j = 0; j < itemList.length; j += 1) {
    if (itemList[j].side === String(BUY_SELL_MAIN.SELL.ID)) {
      sellSum = sellSum.add(
        new Decimal(
          new Decimal(itemList[j].quantity * getMaxPrice(itemList[j]?.entryPrice1, itemList[j]?.entryPrice2))
            .mul(margin)
            .mul(strategySets)
            .toFixed(6, Decimal.ROUND_DOWN),
        ).ceil(),
      );
    } else {
      buySum = buySum.add(
        new Decimal(
          new Decimal(itemList[j].quantity * getMaxPrice(itemList[j]?.entryPrice1, itemList[j]?.entryPrice2))
            .mul(margin)
            .mul(strategySets)
            .toFixed(6, Decimal.ROUND_DOWN),
        ).ceil(),
      );
    }
  }

  return [buySum, sellSum];
};

export const createMarginRequiredReducer = (marginRates) => (totalMargin, strategy) => {
  const { strategySets, strategyDetail } = strategy;
  // strategyDetail == null は lab の strategy
  const { instrumentId, itemList } = strategyDetail ?? strategy;
  if (marginRates[instrumentId] == null) {
    return totalMargin;
  }
  const { margin } = marginRates[instrumentId];
  const bdMargin = new Decimal(margin);
  const bdStrategySets = new Decimal(strategySets);
  const [buySumQuantityRate, sellSumQuantityRate] = getSettingsBuySellMaxQuantity(itemList, bdMargin, bdStrategySets);
  // 買: (数量 * エントリー価格1) * (証拠金率 * 円評価レートMid)
  const buyMarginRequirement = new Decimal(buySumQuantityRate).ceil();
  // 売: (数量 * エントリー価格1) * (証拠金率 * 円評価レートMid)
  const sellMarginRequirement = new Decimal(sellSumQuantityRate).ceil();

  const tempBuySellMarginRequirement = Decimal.max(buyMarginRequirement, sellMarginRequirement).toNumber();

  return totalMargin + tempBuySellMarginRequirement;
};

export const calculateMarginRequired = (strategyList, defaultSets, accountInfo, marginRates) => {
  try {
    if (strategyListNotAuthorized(strategyList, accountInfo)) {
      return 0;
    }
    return strategyList.reduce(createMarginRequiredReducer(marginRates), 0) * defaultSets;
  } catch (error) {
    return 0;
  }
};

export const isTrue = (v) => v === true || v === 'true' || Boolean(v) === true;

export const trimLeftSlash = (str) => str?.replace?.(/^\/+/, '');

export const removeInstrumentIdSuffix = (inputString) => {
  if (inputString?.includes(AP_SUFFIX)) {
    return inputString.replace(AP_SUFFIX, '');
  }
  if (inputString?.includes(M_SUFFIX)) {
    return inputString.replace(M_SUFFIX, '');
  }
  return inputString;
};

export const getSymbolPercent = (percent) => (percent == null || percent === '--' ? '0%' : `${percent}%`);

export const getOmittedCountLabel = (count) => {
  if (count && count >= 10) return '9+';
  if (count) return String(count);
  return null;
};

export const arraysEqual = (arr1, arr2) => {
  return JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort());
};
