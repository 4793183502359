/* eslint-disable import/no-unresolved,import/no-extraneous-dependencies */
import Decimal from 'decimal.js';
import _ from 'lodash';
import intersectionBy from 'lodash/intersectionBy';
import { v4 as uuid } from 'uuid';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  ALL_BRAND_OPTION,
  AP_GROUP_SOURCES,
  AP_GROUP_STATUSES,
  BUY_SELL_CHART_ID_BY_ID,
  BUY_SELL_MAIN,
  BUY_SELL_OPPOSITE_ID,
  ETF,
  EXEC_TIME_SORTING,
  EXPIRATION_TYPE_MAIN,
  FULL_TIME_REG,
  FX,
  MANUAL_POSITION_GROUP_TYPE,
  NUMBER_VALIDATION_REG,
  ORDER_METHOD_MAIN,
  ORDER_TYPES_MAIN,
  POSITION_DELETION_EXPIRE_TIME,
  PRICE_MAX_MULTIPLIER,
  PRICE_MIN_MULTIPLIER,
  QUANTITY_PRECISION,
  QUANTITY_SORTING,
  TRADE_METHODS,
  TRADE_TYPES,
  VALIDATION_ERROR_EMPTY_FIELD,
  VALIDATION_ERROR_INVALID_VALUE,
  VALIDATION_SETTLEMENT_TIME_ERROR_MESSAGE,
  VALIDATION_TIME_ERROR_MESSAGE,
  ALLOWED_TRADE_TYPES,
  REQUIRED_MARGIN_TOOLTIP,
  REQUIRED_MARGIN_LABEL,
  RECOMMENDED_MARGIN_LABEL,
  AP_GROUP_ORDER,
  TECH_STATUS,
  SELECTED_INSTRUMENT_OPTION,
  CFD,
  OPTIONS_TRADE_METHOD_TYPES,
} from '../../constants';
import {
  AP_GROUP_DELETE_MESSAGES,
  POSITIONS_ARE_STILL_DELETING,
  TECH_LOGIC_DELETE_MESSAGES,
} from '../../constants/errorMesages';
import {
  ADDITION_VALIDATION_DATE_INPUTS,
  BUY_SELL_INPUT,
  CHANGED_DATE_VALUES,
  COUNT_INPUT,
  DATE_INPUT,
  DATE_INPUTS,
  DEFAULT_QUANTITY_MULTIPLIERS,
  EXPIRATION_TYPE_INPUT,
  KEY_FOR_DEFAULT_SELECT_SIDE,
  KEY_FOR_DEFAULT_SELECTED_INSTRUMENT_ID,
  LIMIT_PRICE_INPUTS,
  ORDER_METHOD_INPUT,
  ORDER_TYPES,
  ORDER_TYPES_BY_ID,
  PRICE_INPUT,
  PRICE_INPUTS,
  QUANTITY_PRECISION_KEEP,
  SETTLEMENT_DATE_INPUT,
  SETTLEMENT_EXPIRATION_TYPE_INPUT,
  SETTLEMENT_LIMIT_PRICE_INPUT,
  SETTLEMENT_ORDER_METHOD_INPUT,
  SETTLEMENT_PRICE_INPUT,
  SETTLEMENT_PRICE_INPUTS,
  SETTLEMENT_STOP_PRICE_INPUT,
  SETTLEMENT_TIME_INPUT,
  STOP_PRICE_INPUTS,
  TIME_INPUT,
  TIME_INPUTS,
  VALIDATED_INPUTS,
  VALIDATION_DATE_INPUTS,
  VALIDATION_SETTLEMENT_DATE_INPUTS,
} from '../../constants/manualTrade';
import { changeServiceIdRequest } from '../../redux/actions/authActions';
import { openErrorInfoModal } from '../../redux/actions/errorActions';
import {
  changeCreateOrderValidationErrors,
  changeCreateOrderValues,
  changeSelectedOrderType,
  changeSelectedSide,
  changeChartSide,
  changeTradeSelectedInstrumentId,
  resetCreateOrderErrors,
  changeTradeMethodType,
} from '../../redux/actions/manualTradeActions';
import {
  calculateUnrealisedProfitLoss,
  calculateQuantity,
  combineWithInstruments,
  createDateStringByDateAndTime,
  getChangeManualTradeOrderValuesActionsArray,
  getRefreshStateActionsArrayForManualTrade,
  getRoundedPlusOneHourCurrentDate,
  getRoundedPlusOneHourCurrentTime,
  getServiceQuantityUnit,
  getStartMinuteTime,
  getTimeSecondsDifference,
  getTimeValueByExpirationType,
  hasPrecision,
  roundExactlyOnPrecisionMatching,
  roundToFixedPrecision,
  saveDefaultValuesFromLocalStorage,
  validateOrderSettingsCount,
  getPercentage,
  toCommaSeparatedFixedValue,
  getDecimalPlace,
} from '../index';
import { apGroupsSelector, currencyPairsOptionsSelector } from '../../redux/portfolio';
import { useManualMarginRequirement, useMarginRequirement } from '../../hooks/useMarginRequirement';
import { getServiceId, getServiceName, getValidationCurrencyUnitByServiceId, roundUpBy1000 } from '../../utils';
import { ALL_SERVICES, ASSET_TYPES } from '../../constants/core';
import { useAccountInfo } from '../../hooks/useAccountInfo';

import { useCalculatingChartData, useInstrumentOptions } from '../../hooks';
import { removeSuffix, removedDuplicateInstrumentList, useInstrumentGroups } from '../../hooks/symbol';
import { recommendedMarginTooltips } from '../common';

// eslint-disable-next-line max-len
const TIME_CHANGING_REG =
  /^[0-2]$|(^([0-1][0-9]|[2][0-3]):([0-5][0-9])$)|(^([0-1][0-9]|[2][0-3]):?$)|(^([0-1][0-9]|[2][0-3]):[0-5]?$)/;
const HOUR_WITHOUT_ZERO_REG = /^[3-9]$/;
const THREE_NUMBERS_REG = /^\d{3}$/;
const TWO_NUMBERS_WITH_DOUBLE_DOT_REG = /^\d{2}:$/;

export const useTimeChangingHandler = (func) =>
  useCallback(
    (value) => {
      let updatedValue = String(value);
      if (updatedValue.match(HOUR_WITHOUT_ZERO_REG)) updatedValue = `0${updatedValue}`;
      if (updatedValue.match(THREE_NUMBERS_REG)) updatedValue = `${updatedValue.slice(0, 2)}:${updatedValue.slice(2)}`;
      if (updatedValue.match(TWO_NUMBERS_WITH_DOUBLE_DOT_REG)) updatedValue = updatedValue.slice(0, 2);
      if (updatedValue === '' || updatedValue.match(TIME_CHANGING_REG)) func(updatedValue);
    },
    [func],
  );

// TODO: Combine useInstrumentSettings and useManualTradeSelectedInstrumentSettings
export const useInstrumentSettings = (instrumentId, tradeMethod = TRADE_METHODS.MANUAL.ID) => {
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  return useMemo(() => {
    if (instrumentList?.[instrumentId]) {
      const {
        buyPriceMax,
        settings,
        pricePrecision,
        sellPriceMin,
        sellPriceMax,
        buyPriceMin,
        marketStatus,
        shortName,
        image,
        quantityUnitConvFactor,
      } = instrumentList?.[instrumentId];

      return {
        ...(settings?.[tradeMethod] ?? {}),
        marketStatus,
        pricePrecision,
        sellPriceMax,
        sellPriceMin,
        buyPriceMax,
        buyPriceMin,
        shortName,
        image,
        quantityUnitConvFactor,
      };
    }
    return {
      shortName: instrumentId,
    };
  }, [instrumentList, instrumentId, tradeMethod]);
};

export const useManualTradeSelectedInstrumentSettings = () => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const selectedInstrumentId = useSelector((state) => state.manualTrade.selectedInstrumentId[serviceId]);

  return useInstrumentSettings(selectedInstrumentId, TRADE_METHODS.MANUAL.ID);
};

export const useSelectedOrderType = () => {
  const dispatch = useDispatch();
  const value = useSelector((state) => state.manualTrade.selectedOrderType);
  const valueRef = useRef({});

  useEffect(() => {
    if (valueRef?.current) {
      valueRef.current = { value };
    }
  }, [value]);

  const onChange = useCallback(
    (orderType) => {
      const {
        current: { value: prevOrderType },
      } = valueRef;
      if (orderType !== prevOrderType) {
        dispatch(changeSelectedOrderType({ orderType }));
        dispatch(resetCreateOrderErrors());
      }
    },
    [dispatch],
  );
  return [value, onChange];
};

export const useChangeTradeMethodType = () => {
  const dispatch = useDispatch();
  const value = useSelector((state) => state.manualTrade.selectedTradeMethodType);
  const valueRef = useRef({});

  useEffect(() => {
    if (valueRef?.current) {
      valueRef.current = { value };
    }
  }, [value]);

  const onChange = useCallback(
    (tradeMethodType) => {
      const {
        current: { value: prevTradeMethodType },
      } = valueRef;
      if (tradeMethodType !== prevTradeMethodType) {
        dispatch(changeTradeMethodType({ tradeMethodType }));
      }
    },
    [dispatch],
  );
  return [value, onChange];
};

export const useSellForbiddenCurrencyPairSupportAllowSellFlgCalc = (allowSellFlg, buyPositionCount) => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const selectedCurrencyId = useSelector((state) => state.manualTrade.selectedInstrumentId[serviceId]);
  const instrumentSettings = useSelector((state) => state.settings.instrumentList[selectedCurrencyId]);
  const isCrossOrder = useSelector((state) => state.settings[serviceId].isCrossOrder);
  const sellQuantityMax = useMemo(
    () => instrumentSettings?.settings?.[TRADE_METHODS.MANUAL.ID].sellQuantityMax,
    [instrumentSettings],
  );

  return useMemo(() => {
    if (sellQuantityMax !== 0 || isCrossOrder) return allowSellFlg;
    if (buyPositionCount) return true;
    return false;
  }, [isCrossOrder, sellQuantityMax, allowSellFlg, buyPositionCount]);
};

export const useOppositeSideTotalQuantityInfo = () => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const instrumentId = useSelector((state) => state.manualTrade.selectedInstrumentId[serviceId]);
  const positions = useSelector((state) => state.currencies.positions[serviceId]);

  return useMemo(() => {
    const { buyTotalQuantity, sellTotalQuantity } = Object.values(positions || [])
      .filter((data) => data.apGroupId === null && data.instrumentId === instrumentId)
      .reduce(
        (acc, item) => {
          if (Number(item.side) === BUY_SELL_MAIN.BUY.ID)
            acc.buyTotalQuantity += item.quantity * QUANTITY_PRECISION_KEEP;
          if (Number(item.side) === BUY_SELL_MAIN.SELL.ID)
            acc.sellTotalQuantity += item.quantity * QUANTITY_PRECISION_KEEP;
          return acc;
        },
        {
          buyTotalQuantity: 0,
          sellTotalQuantity: 0,
        },
      );

    const quantityInfo = {
      [BUY_SELL_OPPOSITE_ID[BUY_SELL_MAIN.SELL.ID]]: sellTotalQuantity / QUANTITY_PRECISION_KEEP,
      [BUY_SELL_OPPOSITE_ID[BUY_SELL_MAIN.BUY.ID]]: buyTotalQuantity / QUANTITY_PRECISION_KEEP,
    };
    return quantityInfo;
  }, [instrumentId, positions]);
};

export const useCalculateMargin = (count, side) => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const selectedCurrencyId = useSelector((state) => state.manualTrade.selectedInstrumentId[serviceId]);
  return useMarginRequirement(serviceId, selectedCurrencyId, side, count);
};

export const useCalculateManualMargin = (count, price) => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const selectedCurrencyId = useSelector((state) => state.manualTrade.selectedInstrumentId[serviceId]);
  return useManualMarginRequirement(serviceId, selectedCurrencyId, count, price);
};

const useMarginDataSelector = (select) => {
  return useSelector((state) => {
    const { serviceId } = state.auth;
    return select(state.portfolio.marginData[serviceId]);
  });
};

const createMarginDataSelector = (serviceId) => (select) => {
  return useSelector((state) => {
    return select(state.portfolio.marginData[serviceId]);
  });
};

export const useSummaryInfo = () => {
  const receivedMargin = useMarginDataSelector((marginData) => marginData.summary.receivedMargin);
  const positionRequiredMargin = useMarginDataSelector((marginData) => marginData.summary.positionRequiredMargin);
  const orderingRequiredMargin = useMarginDataSelector((marginData) => marginData.summary.orderingRequiredMargin);
  const registeredCashOut = useMarginDataSelector((marginData) => marginData.summary.registeredCashOut);
  const unsettledPl = useMarginDataSelector((marginData) => marginData.unsettledPl);
  const effectiveMargin = useMarginDataSelector((marginData) => marginData.effectiveMargin);
  const instrumentGroups = useMarginDataSelector((marginData) => marginData.instrumentGroups);
  const effectiveMarginRate = useMarginDataSelector((marginData) => marginData.effectiveMarginRate);
  const orderableMargin = useMarginDataSelector((marginData) => marginData.orderableMargin);
  const bindingMoney = useMarginDataSelector((marginData) => marginData.summary.bindingMoney);
  const depositeBalance = useMarginDataSelector((marginData) => marginData.summary.depositeBalance);
  const cpAddMarginAmount = useMarginDataSelector((marginData) => marginData.summary.cpAddMarginAmount);
  const isLoading = useSelector((state) => state.portfolio.changingSummaryInfoLoading);

  return useMemo(
    () => ({
      receivedMargin,
      positionRequiredMargin,
      orderingRequiredMargin,
      registeredCashOut,
      unsettledPl,
      effectiveMargin,
      effectiveMarginRate,
      orderableMargin,
      instrumentGroups,
      bindingMoney,
      depositeBalance,
      cpAddMarginAmount,
      isLoading,
    }),
    [
      bindingMoney,
      effectiveMargin,
      effectiveMarginRate,
      instrumentGroups,
      orderableMargin,
      orderingRequiredMargin,
      positionRequiredMargin,
      receivedMargin,
      registeredCashOut,
      unsettledPl,
      depositeBalance,
      cpAddMarginAmount,
      isLoading,
    ],
  );
};

export const useMockSummaryInfoByServiceId = (serviceId) => {
  return useMemo(() => {
    if (serviceId === FX) {
      return {
        receivedMargin: 4000000,
        positionRequiredMargin: 1330000,
        orderingRequiredMargin: 0,
        registeredCashOut: 0,
        unsettledPl: 0,
        effectiveMargin: 4037755,
        effectiveMarginRate: '689.26 % ',
        orderableMargin: 0,
        instrumentGroups: [],
        depositeBalance: 0,
        cpAddMarginAmount: 0,
        isLoading: false,
      };
    }
    return {
      receivedMargin: 0,
      positionRequiredMargin: 0,
      orderingRequiredMargin: 0,
      registeredCashOut: 0,
      unsettledPl: 0,
      effectiveMargin: 0,
      effectiveMarginRate: '0 % ',
      orderableMargin: 0,
      instrumentGroups: [],
      depositeBalance: 0,
      cpAddMarginAmount: 0,
      isLoading: false,
    };
  }, [serviceId]);
};

export const useSummaryInfoByServiceId = (serviceId) => {
  const receivedMargin = createMarginDataSelector(serviceId)((marginData) => marginData.summary.receivedMargin);
  const positionRequiredMargin = createMarginDataSelector(serviceId)(
    (marginData) => marginData.summary.positionRequiredMargin,
  );
  const orderingRequiredMargin = createMarginDataSelector(serviceId)(
    (marginData) => marginData.summary.orderingRequiredMargin,
  );
  const registeredCashOut = createMarginDataSelector(serviceId)((marginData) => marginData.summary.registeredCashOut);
  const unsettledPl = createMarginDataSelector(serviceId)((marginData) => marginData.unsettledPl);
  const effectiveMargin = createMarginDataSelector(serviceId)((marginData) => marginData.effectiveMargin);
  const instrumentGroups = createMarginDataSelector(serviceId)((marginData) => marginData.instrumentGroups);
  const effectiveMarginRate = createMarginDataSelector(serviceId)((marginData) => marginData.effectiveMarginRate);
  const orderableMargin = createMarginDataSelector(serviceId)((marginData) => marginData.orderableMargin);
  const bindingMoney = createMarginDataSelector(serviceId)((marginData) => marginData.summary.bindingMoney);
  const depositeBalance = createMarginDataSelector(serviceId)((marginData) => marginData.summary.depositeBalance);
  const cpAddMarginAmount = createMarginDataSelector(serviceId)((marginData) => marginData.summary.cpAddMarginAmount);
  const isLoading = useSelector((state) => state.portfolio.changingSummaryInfoLoading);

  return useMemo(
    () => ({
      receivedMargin,
      positionRequiredMargin,
      orderingRequiredMargin,
      registeredCashOut,
      unsettledPl,
      effectiveMargin,
      effectiveMarginRate,
      orderableMargin,
      instrumentGroups,
      bindingMoney,
      depositeBalance,
      cpAddMarginAmount,
      isLoading,
    }),
    [
      bindingMoney,
      effectiveMargin,
      effectiveMarginRate,
      instrumentGroups,
      orderableMargin,
      orderingRequiredMargin,
      positionRequiredMargin,
      receivedMargin,
      registeredCashOut,
      unsettledPl,
      depositeBalance,
      cpAddMarginAmount,
      isLoading,
    ],
  );
};

const useMarginZeroCalc = (summaryInfo) => {
  return useMemo(
    () => summaryInfo.effectiveMargin <= 0 && summaryInfo.orderableMargin <= 0,
    [summaryInfo.effectiveMargin, summaryInfo.orderableMargin],
  );
};

export const useIsMarginZero = () => {
  const summaryInfo = useSummaryInfo();

  return useMarginZeroCalc(summaryInfo);
};

export const useIsMarginZeroByServiceId = (serviceId) => {
  const summaryInfo = useSummaryInfoByServiceId(serviceId);

  return useMarginZeroCalc(summaryInfo);
};

const useCartMarginDataSelector = (select, serviceId) => {
  return useSelector((state) => {
    return select(state.portfolio.marginData[serviceId]);
  });
};

export const useCartSummaryInfo = (serviceId) => {
  const receivedMargin = useCartMarginDataSelector((marginData) => marginData.summary.receivedMargin, serviceId);
  const positionRequiredMargin = useCartMarginDataSelector(
    (marginData) => marginData.summary.positionRequiredMargin,
    serviceId,
  );
  const orderingRequiredMargin = useCartMarginDataSelector(
    (marginData) => marginData.summary.orderingRequiredMargin,
    serviceId,
  );
  const registeredCashOut = useCartMarginDataSelector((marginData) => marginData.summary.registeredCashOut, serviceId);
  const unsettledPl = useCartMarginDataSelector((marginData) => marginData.unsettledPl, serviceId);
  const effectiveMargin = useCartMarginDataSelector((marginData) => marginData.effectiveMargin, serviceId);
  const instrumentGroups = useCartMarginDataSelector((marginData) => marginData.instrumentGroups, serviceId);
  const effectiveMarginRate = useCartMarginDataSelector((marginData) => marginData.effectiveMarginRate, serviceId);
  const orderableMargin = useCartMarginDataSelector((marginData) => marginData.orderableMargin, serviceId);
  const bindingMoney = useCartMarginDataSelector((marginData) => marginData.summary.bindingMoney, serviceId);
  const depositeBalance = useCartMarginDataSelector((marginData) => marginData.summary.depositeBalance, serviceId);
  const cpAddMarginAmount = useCartMarginDataSelector((marginData) => marginData.summary.cpAddMarginAmount, serviceId);
  const isLoading = useSelector((state) => state.cart.changingSummaryInfoLoading);

  return useMemo(
    () => ({
      receivedMargin,
      positionRequiredMargin,
      orderingRequiredMargin,
      registeredCashOut,
      unsettledPl,
      effectiveMargin,
      effectiveMarginRate,
      orderableMargin,
      instrumentGroups,
      bindingMoney,
      depositeBalance,
      cpAddMarginAmount,
      isLoading,
    }),
    [
      bindingMoney,
      effectiveMargin,
      effectiveMarginRate,
      instrumentGroups,
      orderableMargin,
      orderingRequiredMargin,
      positionRequiredMargin,
      receivedMargin,
      registeredCashOut,
      unsettledPl,
      depositeBalance,
      cpAddMarginAmount,
      isLoading,
    ],
  );
};

// constant and helper for usePercentSummaryInfo
const MARGIN_COLORS = [
  ['#725ae9', '#268bfc', '#387c77', '#fec85d', '#e8546c', '#59d2cc'],
  ['#d1c9f8', '#d6e9fe', '#67c7b4', '#fff1d5', '#f7bdc7', '#dbf7f1'],
];
const ORDERABLE_COLORS = ['#D0D0D0', '#C0C0C0'];

const INCLUDE_CONSUMED_COLORS = [
  [...MARGIN_COLORS[0], '#ff734b', ORDERABLE_COLORS[0]],
  [...MARGIN_COLORS[1], '#ffab92', ORDERABLE_COLORS[1]],
];
const COLORS = [
  [...MARGIN_COLORS[0], ORDERABLE_COLORS[0]],
  [...MARGIN_COLORS[1], ORDERABLE_COLORS[1]],
];

const checkAndGetPercentageValue = (value) => {
  if (value && value.percent && Number(value.percent) !== 0) return value;
  return null;
};

export const usePercentSummaryInfo = (consumedMargin, summaryInfo, serviceId) => {
  const instrumentList = useSelector((state) => state.settings.instrumentList);

  const { instrumentGroups, effectiveMargin, receivedMargin, orderableMargin, depositeBalance } = summaryInfo;
  const mergedInstrumentGroups = useInstrumentGroups(instrumentGroups, serviceId);
  const mergedInstrumentList = removedDuplicateInstrumentList(instrumentList, serviceId);

  const isFX = serviceId === FX;

  const topSortedPositions = [
    ...combineWithInstruments(mergedInstrumentGroups, mergedInstrumentList, 'instrumentId').sort(
      (a, b) => b.positionRequiredMargin - a.positionRequiredMargin,
    ),
  ];
  const topSortedOrders = [
    ...combineWithInstruments(mergedInstrumentGroups, mergedInstrumentList, 'instrumentId').sort(
      (a, b) => b.orderingRequiredMargin - a.orderingRequiredMargin,
    ),
  ];

  const [top1Position, top2Position] = topSortedPositions.map(
    ({ positionRequiredMargin, instrumentId, shortName }) => ({
      name: isFX ? instrumentId : shortName,
      percent: getPercentage(positionRequiredMargin, effectiveMargin),
      value: positionRequiredMargin,
    }),
  );

  const [top1Order, top2Order] = topSortedOrders.map(({ orderingRequiredMargin, instrumentId, shortName }) => ({
    name: `${isFX ? instrumentId : shortName} 注文`,
    percent: getPercentage(orderingRequiredMargin, effectiveMargin),
    value: orderingRequiredMargin,
  }));

  const leftPositionValue = topSortedPositions
    .slice(2)
    .reduce((accMargin, { positionRequiredMargin }) => accMargin.add(positionRequiredMargin), new Decimal(0))
    .toNumber();

  const leftPosition = {
    name: 'その他',
    percent: getPercentage(leftPositionValue + depositeBalance || 0, effectiveMargin),
    value: leftPositionValue + depositeBalance || 0,
  };

  const leftOrderValue = topSortedOrders
    .slice(2)
    .reduce((accMargin, { orderingRequiredMargin }) => accMargin.add(orderingRequiredMargin), new Decimal(0))
    .toNumber();

  const leftOrder = {
    name: 'その他 注文',
    percent: getPercentage(leftOrderValue, effectiveMargin),
    value: leftOrderValue,
  };

  const addedMargin = consumedMargin > orderableMargin ? orderableMargin : consumedMargin;
  const getOrderMarginPercentage = () => {
    return consumedMargin > orderableMargin
      ? 0
      : getPercentage(Decimal.sub(orderableMargin, addedMargin || 0), effectiveMargin);
  };
  const orderMargin = {
    name: '発注可能額',
    percent: (receivedMargin ?? 0) > 0 ? getOrderMarginPercentage() : '--',
    value: (receivedMargin ?? 0) > 0 ? orderableMargin - (addedMargin || 0) : 0,
  };

  // create resulted data array
  let resultedData = [top1Position, top2Position, leftPosition, top1Order, top2Order, leftOrder, orderMargin];
  let colors = COLORS;
  if (addedMargin !== undefined) {
    if (addedMargin <= 0 && orderableMargin <= 0) {
      resultedData.splice(resultedData.length - 1, 0, {
        name: '追加分',
        percent: '--',
        value: 1,
        isSimulationData: true,
      });
    } else {
      resultedData.splice(resultedData.length - 1, 0, {
        name: '追加分',
        percent: getPercentage(consumedMargin, effectiveMargin) || 0,
        value: consumedMargin,
        isSimulationData: true,
      });
    }
    colors = INCLUDE_CONSUMED_COLORS;
  }
  const createElement = (el, idx) => ({ ...el, id: idx, color: colors[0][idx], gradationColor: colors[1][idx] });
  const lastIdx = resultedData.length - 1;
  resultedData = resultedData.reduce((acc, el, idx) => {
    // check if this is orderMargin
    if (idx !== lastIdx && !el?.isSimulationData) {
      const validatedValue = checkAndGetPercentageValue(el);
      if (validatedValue) acc.push(createElement(el, idx));
    } else acc.push(createElement(el, idx));

    return acc;
  }, []);

  return resultedData.filter((x) => x.percent >= 0 || x.percent === '--');
};

export const usePortfolioPercentSummaryInfoByServiceId = (consumedMargin, serviceId) => {
  const summaryInfo = useSummaryInfoByServiceId(serviceId);
  return usePercentSummaryInfo(consumedMargin, summaryInfo, serviceId);
};

export const usePricesForBuySell = ({ currencyPair }) => {
  const selectedCurrency = useSelector((state) => state.currencies.rates[currencyPair]);
  const serviceId = useSelector((state) => state.auth.serviceId);
  const isFX = serviceId === FX;
  const pricePrecision = useSelector((state) => state.settings.instrumentList[currencyPair]?.pricePrecision || 1);

  const sellPrice = useMemo(() => {
    if (!selectedCurrency) {
      return 0;
    }
    if (isFX) {
      return selectedCurrency.bid;
    }
    return Number(roundToFixedPrecision(selectedCurrency.bid, pricePrecision));
  }, [selectedCurrency, isFX, pricePrecision]);

  const buyPrice = useMemo(() => {
    if (!selectedCurrency) {
      return 0;
    }
    if (isFX) {
      return selectedCurrency.ask;
    }
    return Number(roundToFixedPrecision(selectedCurrency.ask, pricePrecision));
  }, [selectedCurrency, isFX, pricePrecision]);

  const previousSellPrice = useMemo(() => {
    if (!selectedCurrency) {
      return null;
    }
    if (isFX) {
      return selectedCurrency.previousBid;
    }
    return Number(roundToFixedPrecision(selectedCurrency.previousBid, pricePrecision));
  }, [selectedCurrency, pricePrecision, isFX]);

  const previousBuyPrice = useMemo(() => {
    if (!selectedCurrency) {
      return null;
    }
    if (isFX) {
      return selectedCurrency.previousAsk;
    }
    return Number(roundToFixedPrecision(selectedCurrency.previousAsk, pricePrecision));
  }, [selectedCurrency, pricePrecision, isFX]);

  const sellPriceHigh = useMemo(() => {
    if (!selectedCurrency) {
      return 0;
    }
    if (isFX) {
      return selectedCurrency.bidHigh;
    }
    return Number(roundToFixedPrecision(selectedCurrency.bidHigh, pricePrecision));
  }, [selectedCurrency, isFX, pricePrecision]);

  const sellPriceLow = useMemo(() => {
    if (!selectedCurrency) {
      return 0;
    }
    if (isFX) {
      return selectedCurrency.askLow;
    }
    return Number(roundToFixedPrecision(selectedCurrency.askLow, pricePrecision));
  }, [selectedCurrency, isFX, pricePrecision]);

  const sellPriceClose = useMemo(() => {
    if (!selectedCurrency) {
      return 0;
    }
    if (isFX) {
      return selectedCurrency.bidClose;
    }
    return Number(roundToFixedPrecision(selectedCurrency.bidClose, pricePrecision));
  }, [selectedCurrency, isFX, pricePrecision]);

  return useMemo(
    () => ({ sellPrice, buyPrice, previousSellPrice, previousBuyPrice, sellPriceHigh, sellPriceLow, sellPriceClose }),
    [buyPrice, previousBuyPrice, previousSellPrice, sellPrice, sellPriceClose, sellPriceHigh, sellPriceLow],
  );
};

// TODO: Remove then precision validation extended
const INSTRUMENT_LIST_REQUIRE_PRECISION_CHECK = [
  '1321.TKS/JPY', // 日経225ETF
  '1570.TKS/JPY', // 日経ﾚﾊﾞ
];

export const useValidateCommonInputByRules = (instrumentId) => {
  const { buyPrice, sellPrice } = usePricesForBuySell({
    currencyPair: instrumentId,
  });

  const serviceId = useSelector((state) => state.auth.serviceId);
  const tradeUnit = getServiceQuantityUnit(serviceId);
  const currencyUnit = getValidationCurrencyUnitByServiceId(instrumentId, serviceId);
  const allowedTradeType = useSelector((state) => state.settings.accountInfo[serviceId]?.canTradeType);
  const withoutSuffixInstrumentId = removeSuffix(instrumentId);

  const {
    buyQuantityMin,
    buyQuantityMax,
    sellQuantityMin,
    sellQuantityMax,
    pricePrecision,
    quantityPrecision,
    buyPriceMin,
    sellPriceMin,
  } = useInstrumentSettings(instrumentId, TRADE_METHODS.MANUAL.ID);

  const rates = useSelector((state) => state.currencies.rates);
  const { askClose, bidClose } = useMemo(() => {
    if (rates?.[instrumentId]) return rates[instrumentId];
    return { askClose: 0, bidClose: 0 };
  }, [rates, instrumentId]);
  const isFX = useMemo(() => serviceId === FX, [serviceId]);
  const quantityMin = useMemo(
    () => ({
      [BUY_SELL_MAIN.SELL.ID]: sellQuantityMin,
      [BUY_SELL_MAIN.BUY.ID]: buyQuantityMin,
    }),
    [sellQuantityMin, buyQuantityMin],
  );
  const quantityMax = useMemo(
    () => ({
      [BUY_SELL_MAIN.SELL.ID]: sellQuantityMax,
      [BUY_SELL_MAIN.BUY.ID]: buyQuantityMax,
    }),
    [sellQuantityMax, buyQuantityMax],
  );

  // TODO: use hasPrecision instead, once rule extension is approved
  const hasPrecisionOrCheckOmitted = useCallback(
    (value, precision) =>
      !INSTRUMENT_LIST_REQUIRE_PRECISION_CHECK.includes(instrumentId) || hasPrecision(value, precision),
    [instrumentId],
  );

  const validateSecondPrice = useCallback(
    (value) => {
      let errorMessage = '';
      if (value === '') {
        errorMessage = VALIDATION_ERROR_EMPTY_FIELD;
      } else if (Number.isNaN(value)) {
        errorMessage = VALIDATION_ERROR_INVALID_VALUE;
      } else if (!isFX && !hasPrecisionOrCheckOmitted(value, pricePrecision)) {
        errorMessage = `${pricePrecision}${currencyUnit}単位でご設定ください。`;
      }
      return { isValid: errorMessage === '', errorMessage };
    },
    [pricePrecision, currencyUnit, isFX, hasPrecisionOrCheckOmitted],
  );

  const validateQuantity = useCallback(
    (value, side, isCloseOrder = false, closeOrderMaxQuantity) => {
      const isSell = Number(side) === BUY_SELL_MAIN.SELL.ID;

      const quantityPrecisionFX = quantityPrecision;
      const quantityPrecisionOther = Number.isInteger(Number(value)) ? quantityPrecision : 0.1;
      const quantityPrecisionInner = isFX ? quantityPrecisionFX : quantityPrecisionOther;

      const closeOrderMessageMap = {
        [FX]: `${quantityPrecisionInner}万以上発注可能数量以下、${quantityPrecisionInner}万通貨単位でご設定ください。`,
        [ETF]: `${closeOrderMaxQuantity}口以下、${quantityPrecisionInner}口単位でご設定ください。`,
        [CFD]: `${closeOrderMaxQuantity}Lot以下、${quantityPrecisionInner}Lot単位でご設定ください。`,
      };

      if (
        value < (isCloseOrder ? quantityPrecisionInner : quantityMin[side]) ||
        value > (isCloseOrder ? closeOrderMaxQuantity : quantityMax[side]) ||
        (quantityPrecisionInner >= 1 && value % quantityPrecisionInner !== 0)
      ) {
        if (isCloseOrder) {
          const closeOrderMessage = closeOrderMessageMap[serviceId];
          return {
            isValid: false,
            errorMessage: closeOrderMessage,
          };
        }
        if (!isFX && isSell) {
          if (quantityMax[side] === 0) {
            return {
              isValid: false,
              errorMessage: `現在、この銘柄の新規売り注文は受付できません。`,
            };
          }
          if (quantityMin[side] === quantityMax[side]) {
            return {
              isValid: false,
              errorMessage: `${quantityMin[side]}${tradeUnit}でご設定ください。`,
            };
          }
        }
        return {
          isValid: false,
          errorMessage: `${quantityMin[side]}${tradeUnit}以上${
            quantityMax[side]
          }${tradeUnit}以下、${quantityPrecisionInner}${tradeUnit}${isFX ? '通貨' : ''}単位でご設定ください。`, // eslint-disable-line
        };
      }
      return { isValid: true, errorMessage: '' };
    },
    [quantityMin, quantityMax, quantityPrecision, isFX, tradeUnit, serviceId],
  );

  const validateFIFOQuantity = useCallback(
    (value, side, oppositeSideTotalQuantity) => {
      const isSell = Number(side) === BUY_SELL_MAIN.SELL.ID;
      const notAllowFlg = Number(allowedTradeType) === ALLOWED_TRADE_TYPES.NEW_ORDERS_NOT_ALLOWED.ID;
      const quantityPrecisionFX = quantityPrecision;
      const quantityPrecisionOther = Number.isInteger(Number(value)) ? quantityPrecision : 0.1;
      const quantityPrecisionInner = isFX ? quantityPrecisionFX : quantityPrecisionOther;

      const innerQuantityMax =
        quantityMax[side] === 0 || oppositeSideTotalQuantity ? oppositeSideTotalQuantity : quantityMax[side];
      const innerQuantityMin =
        quantityMax[side] === 0 || oppositeSideTotalQuantity ? quantityPrecisionInner : quantityMin[side];
      const newOrderExistsQuantityMin = new Decimal(oppositeSideTotalQuantity)
        .add(new Decimal(quantityMin[side]))
        .toNumber();
      const newOrderExistsQuantityMax = new Decimal(oppositeSideTotalQuantity)
        .add(new Decimal(quantityMax[side]))
        .toNumber();

      const isCloseOrderOnly = oppositeSideTotalQuantity !== 0 && quantityMax[side] === 0;

      if (notAllowFlg) {
        if (
          value > oppositeSideTotalQuantity ||
          value < innerQuantityMin ||
          (quantityPrecisionInner >= 1 && value % quantityPrecisionInner !== 0)
        ) {
          if (serviceId === ETF) {
            return {
              isValid: false,
              errorMessage:
                instrumentId === '1306.TKS/JPY'
                  ? `10${tradeUnit}以上${oppositeSideTotalQuantity}${tradeUnit}以下、10口単位でご設定ください。`
                  : `1${tradeUnit}以上${oppositeSideTotalQuantity}${tradeUnit}以下、1口単位でご設定ください。`,
            };
          }
          if (serviceId === CFD) {
            return {
              isValid: false,
              errorMessage: `1${tradeUnit}以上${oppositeSideTotalQuantity}${tradeUnit}以下、1${tradeUnit}単位でご設定ください。`,
            };
          }
          return {
            isValid: false,
            errorMessage:
              withoutSuffixInstrumentId === 'ZAR/JPY'
                ? `1${tradeUnit}以上${oppositeSideTotalQuantity}${tradeUnit}以下、1万通貨単位でご設定ください。`
                : `0.1${tradeUnit}以上${oppositeSideTotalQuantity}${tradeUnit}以下、0.1万通貨単位でご設定ください。`,
          };
        }
      }

      if (
        value < innerQuantityMin ||
        (innerQuantityMax < value && (value < newOrderExistsQuantityMin || newOrderExistsQuantityMax < value)) ||
        (quantityPrecisionInner >= 1 && value % quantityPrecisionInner !== 0)
      ) {
        if (isCloseOrderOnly) {
          const closeOrderMessage = isFX
            ? `${quantityPrecisionInner}万以上発注可能数量以下、${quantityPrecisionInner}万通貨単位でご設定ください。`
            : `${innerQuantityMax}${tradeUnit}以下、${quantityPrecisionInner}${tradeUnit}単位でご設定ください。`;
          return {
            isValid: false,
            errorMessage: closeOrderMessage,
          };
        }
        if (!isFX && isSell) {
          if (innerQuantityMax === 0) {
            return {
              isValid: false,
              errorMessage: `現在、この銘柄の新規売り注文は受付できません。`,
            };
          }
          if (quantityMin[side] === innerQuantityMax && !oppositeSideTotalQuantity) {
            return {
              isValid: false,
              errorMessage: `${quantityMin[side]}${tradeUnit}でご設定ください。`,
            };
          }
        }
        if (oppositeSideTotalQuantity) {
          return {
            isValid: false,
            errorMessage: `${
              quantityPrecision === oppositeSideTotalQuantity
                ? `${oppositeSideTotalQuantity}${tradeUnit}`
                : `${quantityPrecision}${tradeUnit}以上${oppositeSideTotalQuantity}${tradeUnit}以下、`
            }または${
              newOrderExistsQuantityMin === newOrderExistsQuantityMax
                ? `${newOrderExistsQuantityMax}${tradeUnit}`
                : `${newOrderExistsQuantityMin}${tradeUnit}以上${newOrderExistsQuantityMax}${tradeUnit}以下`
            }${
              quantityPrecision === oppositeSideTotalQuantity && newOrderExistsQuantityMin === newOrderExistsQuantityMax
                ? ``
                : `、${quantityPrecisionInner}${tradeUnit}${isFX ? '通貨' : ''}単位`
            }でご設定ください。`, // eslint-disable-line
          };
        }
        return {
          isValid: false,
          errorMessage: `${innerQuantityMin}${tradeUnit}以上${innerQuantityMax}${
            tradeUnit // eslint-disable-line
          }以下、${quantityPrecisionInner}${tradeUnit}${isFX ? '通貨' : ''}単位でご設定ください。`, // eslint-disable-line
        };
      }
      return { isValid: true, errorMessage: '' };
    },
    [
      allowedTradeType,
      quantityPrecision,
      isFX,
      quantityMax,
      quantityMin,
      serviceId,
      withoutSuffixInstrumentId,
      tradeUnit,
      instrumentId,
    ],
  );

  const validateAskLimitPrice = useCallback(
    (value) => {
      let errorMessage = { isValid: true, errorMessage: '' };
      const convertedValue = Number(value);
      const minValue = roundToFixedPrecision(
        isFX ? Decimal.mul(buyPrice, PRICE_MIN_MULTIPLIER).toNumber() : buyPriceMin,
        pricePrecision,
      );
      if (value === '') {
        errorMessage = { isValid: false, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
      } else if (Number.isNaN(convertedValue)) {
        errorMessage = { isValid: false, errorMessage: VALIDATION_ERROR_INVALID_VALUE };
      } else if (
        (isFX
          ? convertedValue <= minValue
          : convertedValue < minValue || !hasPrecisionOrCheckOmitted(convertedValue, pricePrecision)) ||
        convertedValue > buyPrice
      ) {
        errorMessage = {
          isValid: false,
          errorMessage: isFX
            ? `${minValue}より高い価格、${buyPrice}以下、小数点${getDecimalPlace(pricePrecision)}位でご設定ください。`
            : `${minValue}${currencyUnit}以上、${buyPrice}${currencyUnit}以下、${pricePrecision}${currencyUnit}単位でご設定ください。`, // eslint-disable-line max-len
        };
      }
      return errorMessage;
    },
    [buyPrice, pricePrecision, isFX, buyPriceMin, currencyUnit, hasPrecisionOrCheckOmitted],
  );

  const validateAskStopPrice = useCallback(
    (value) => {
      let errorMessage = { isValid: true, errorMessage: '' };
      const maxValue = roundToFixedPrecision(
        Number(isFX ? Decimal.mul(buyPrice, PRICE_MAX_MULTIPLIER) : Decimal.mul(askClose, 10)),
        pricePrecision,
      );
      const convertedValue = Number(value);
      if (value === '') {
        errorMessage = { isValid: false, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
      } else if (Number.isNaN(convertedValue)) {
        errorMessage = { isValid: false, errorMessage: VALIDATION_ERROR_INVALID_VALUE };
      } else if (
        convertedValue < buyPrice ||
        (isFX
          ? convertedValue >= maxValue
          : convertedValue > maxValue || !hasPrecisionOrCheckOmitted(convertedValue, pricePrecision))
      ) {
        errorMessage = {
          isValid: false,
          errorMessage: isFX
            ? `${buyPrice}以上、${maxValue}より低い価格、小数点${getDecimalPlace(pricePrecision)}位でご設定ください。`
            : `${buyPrice}${currencyUnit}以上、${maxValue}${currencyUnit}以下、${pricePrecision}${currencyUnit}単位でご設定ください。`, // eslint-disable-line max-len
        };
      }
      return errorMessage;
    },
    [buyPrice, pricePrecision, isFX, askClose, currencyUnit, hasPrecisionOrCheckOmitted],
  );

  const validateBidLimitPrice = useCallback(
    (value) => {
      let errorMessage = { isValid: true, errorMessage: '' };
      const maxValue = roundToFixedPrecision(
        Number(isFX ? Decimal.mul(sellPrice, PRICE_MAX_MULTIPLIER) : Decimal.mul(bidClose, 10)),
        pricePrecision,
      );
      const convertedValue = Number(value);
      if (value === '') {
        errorMessage = { isValid: false, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
      } else if (Number.isNaN(convertedValue)) {
        errorMessage = { isValid: false, errorMessage: VALIDATION_ERROR_INVALID_VALUE };
      } else if (
        convertedValue < sellPrice ||
        (isFX
          ? convertedValue >= maxValue
          : convertedValue > maxValue || !hasPrecisionOrCheckOmitted(convertedValue, pricePrecision))
      ) {
        errorMessage = {
          isValid: false,
          errorMessage: isFX
            ? `${sellPrice}以上、${maxValue}より低い価格、小数点${getDecimalPlace(pricePrecision)}位でご設定ください。`
            : `${sellPrice}${currencyUnit}以上、${maxValue}${currencyUnit}以下、${pricePrecision}${currencyUnit}単位でご設定ください。`, // eslint-disable-line max-len
        };
      }
      return errorMessage;
    },
    [sellPrice, pricePrecision, isFX, bidClose, currencyUnit, hasPrecisionOrCheckOmitted],
  );

  const validateBidStopPrice = useCallback(
    (value) => {
      let errorMessage = { isValid: true, errorMessage: '' };
      const minValue = roundToFixedPrecision(
        isFX ? Decimal.mul(sellPrice, PRICE_MIN_MULTIPLIER).toNumber() : sellPriceMin,
        pricePrecision,
      );
      const convertedValue = Number(value);
      if (value === '') {
        errorMessage = { isValid: false, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
      } else if (Number.isNaN(convertedValue)) {
        errorMessage = { isValid: false, errorMessage: VALIDATION_ERROR_INVALID_VALUE };
      } else if (
        (isFX
          ? convertedValue <= minValue
          : convertedValue < minValue || !hasPrecisionOrCheckOmitted(convertedValue, pricePrecision)) ||
        convertedValue > sellPrice
      ) {
        errorMessage = {
          isValid: false,
          errorMessage: isFX
            ? `${minValue}より高い価格、${sellPrice}以下、小数点${getDecimalPlace(pricePrecision)}位でご設定ください。`
            : `${minValue}${currencyUnit}以上、${sellPrice}${currencyUnit}以下、${pricePrecision}${currencyUnit}単位でご設定ください。`, // eslint-disable-line max-len
        };
      }
      return errorMessage;
    },
    [sellPrice, pricePrecision, sellPriceMin, isFX, currencyUnit, hasPrecisionOrCheckOmitted],
  );
  const validateTime = useCallback((time) => {
    const timeDiff = getTimeSecondsDifference(getStartMinuteTime(time), getStartMinuteTime(new Date()));
    if (timeDiff < 59) {
      return {
        isValid: false,
        errorMessage: VALIDATION_TIME_ERROR_MESSAGE,
      };
    }
    return { isValid: true, errorMessage: '' };
  }, []);

  const validateIFDBuyLimitSellLimitPrice = useCallback(
    (ifPrice, donePrice, ifIsReadOnly = false) => {
      const convertedIfPrice = Number(ifPrice);
      const convertedDonePrice = Number(donePrice);
      const ifError = ifIsReadOnly ? { isValid: true, errorMessage: '' } : validateAskLimitPrice(convertedIfPrice);
      let doneError = validateSecondPrice(convertedDonePrice);
      if (doneError.isValid && convertedDonePrice <= convertedIfPrice) {
        doneError = {
          isValid: false,
          errorMessage: 'Done注文の指値価格はIF注文の指定価格より高い価格を設定してください。',
        };
      }
      return { ifPriceMessage: ifError, donePriceMessage: doneError };
    },
    [validateAskLimitPrice, validateSecondPrice],
  );

  const validateIFDBuyLimitSellStopPrice = useCallback(
    (ifPrice, donePrice, ifIsReadOnly = false) => {
      const convertedIfPrice = Number(ifPrice);
      const convertedDonePrice = Number(donePrice);
      const ifError = ifIsReadOnly ? { isValid: true, errorMessage: '' } : validateAskLimitPrice(convertedIfPrice);
      let doneError = validateSecondPrice(convertedDonePrice);
      if (doneError.isValid && convertedDonePrice >= convertedIfPrice) {
        doneError = {
          isValid: false,
          errorMessage: 'Done注文の逆指値価格はIF注文の指定価格より低い価格を設定してください。',
        };
      }

      return { ifPriceMessage: ifError, donePriceMessage: doneError };
    },
    [validateAskLimitPrice, validateSecondPrice],
  );
  const validateIFDBuyStopSellLimitPrice = useCallback(
    (ifPrice, donePrice, ifIsReadOnly = false) => {
      const convertedIfPrice = Number(ifPrice);
      const convertedDonePrice = Number(donePrice);
      const ifError = ifIsReadOnly ? { isValid: true, errorMessage: '' } : validateAskStopPrice(convertedIfPrice);
      let doneError = validateSecondPrice(convertedDonePrice);
      if (doneError.isValid && convertedDonePrice <= convertedIfPrice) {
        doneError = {
          isValid: false,
          errorMessage: 'Done注文の指値価格はIF注文の指定価格より高い価格を設定してください。',
        };
      }
      return { ifPriceMessage: ifError, donePriceMessage: doneError };
    },
    [validateAskStopPrice, validateSecondPrice],
  );
  const validateIFDBuyStopSellStopPrice = useCallback(
    (ifPrice, donePrice, ifIsReadOnly = false) => {
      const convertedIfPrice = Number(ifPrice);
      const convertedDonePrice = Number(donePrice);
      const ifError = ifIsReadOnly ? { isValid: true, errorMessage: '' } : validateAskStopPrice(convertedIfPrice);
      let doneError = validateSecondPrice(convertedDonePrice);
      if (doneError.isValid && convertedDonePrice >= convertedIfPrice) {
        doneError = {
          isValid: false,
          errorMessage: 'Done注文の逆指値価格はIF注文の指定価格より低い価格を設定してください。',
        };
      }
      return { ifPriceMessage: ifError, donePriceMessage: doneError };
    },
    [validateAskStopPrice, validateSecondPrice],
  );
  const validateIFDSellLimitBuyLimitPrice = useCallback(
    (ifPrice, donePrice, ifIsReadOnly = false) => {
      const convertedIfPrice = Number(ifPrice);
      const convertedDonePrice = Number(donePrice);
      const ifError = ifIsReadOnly ? { isValid: true, errorMessage: '' } : validateBidLimitPrice(convertedIfPrice);
      let doneError = validateSecondPrice(convertedDonePrice);
      if (doneError.isValid && convertedDonePrice >= convertedIfPrice) {
        doneError = {
          isValid: false,
          errorMessage: 'Done注文の指値価格はIF注文の指定価格より低い価格を設定してください。',
        };
      }
      return { ifPriceMessage: ifError, donePriceMessage: doneError };
    },
    [validateBidLimitPrice, validateSecondPrice],
  );
  const validateIFDSellLimitBuyStopPrice = useCallback(
    (ifPrice, donePrice, ifIsReadOnly = false) => {
      const convertedIfPrice = Number(ifPrice);
      const convertedDonePrice = Number(donePrice);
      const ifError = ifIsReadOnly ? { isValid: true, errorMessage: '' } : validateBidLimitPrice(convertedIfPrice);
      let doneError = validateSecondPrice(convertedDonePrice);
      if (doneError.isValid && convertedDonePrice <= convertedIfPrice) {
        doneError = {
          isValid: false,
          errorMessage: 'Done注文の逆指値価格はIF注文の指定価格より高い価格を設定してください。',
        };
      }
      return { ifPriceMessage: ifError, donePriceMessage: doneError };
    },
    [validateBidLimitPrice, validateSecondPrice],
  );
  const validateIFDSellStopBuyLimitPrice = useCallback(
    (ifPrice, donePrice, ifIsReadOnly = false) => {
      const convertedIfPrice = Number(ifPrice);
      const convertedDonePrice = Number(donePrice);
      const ifError = ifIsReadOnly ? { isValid: true, errorMessage: '' } : validateBidStopPrice(convertedIfPrice);
      let doneError = validateSecondPrice(convertedDonePrice);
      if (doneError.isValid && convertedDonePrice >= convertedIfPrice) {
        doneError = {
          isValid: false,
          errorMessage: 'Done注文の指値価格はIF注文の指定価格より低い価格を設定してください。',
        };
      }
      return { ifPriceMessage: ifError, donePriceMessage: doneError };
    },
    [validateBidStopPrice, validateSecondPrice],
  );
  const validateIFDSellStopBuyStopPrice = useCallback(
    (ifPrice, donePrice, ifIsReadOnly = false) => {
      const convertedIfPrice = Number(ifPrice);
      const convertedDonePrice = Number(donePrice);
      const ifError = ifIsReadOnly ? { isValid: true, errorMessage: '' } : validateBidStopPrice(convertedIfPrice);
      let doneError = validateSecondPrice(convertedDonePrice);
      if (doneError.isValid && convertedDonePrice <= convertedIfPrice) {
        doneError = {
          isValid: false,
          errorMessage: 'Done注文の逆指値価格はIF注文の指定価格より高い価格を設定してください。',
        };
      }
      return { ifPriceMessage: ifError, donePriceMessage: doneError };
    },
    [validateBidStopPrice, validateSecondPrice],
  );
  const validateIFOBuyLimitSellLimitSellStop = useCallback(
    (ifPrice, oco1Price, oco2Price, ifIsReadOnly = false) => {
      const convertedIfPrice = Number(ifPrice);
      const convertedOco1Price = Number(oco1Price);
      const convertedOco2Price = Number(oco2Price);
      const ifPriceError = ifIsReadOnly ? { isValid: true, errorMessage: '' } : validateAskLimitPrice(convertedIfPrice);

      let oco1Error = validateSecondPrice(convertedOco1Price);
      if (oco1Error.isValid && convertedOco1Price <= convertedIfPrice) {
        oco1Error = {
          isValid: false,
          errorMessage: 'OCO1注文の売り指値価格はIF注文の価格より高い価格を設定してください。',
        };
      }

      let oco2Error = validateSecondPrice(convertedOco2Price);
      if (oco2Error.isValid && convertedOco2Price >= convertedIfPrice) {
        oco2Error = {
          isValid: false,
          errorMessage: 'OCO2注文の売り逆指値価格はIF注文の価格より低い価格を設定してください。',
        };
      }

      return {
        ifPriceMessage: ifPriceError,
        oco1PriceMessage: oco1Error,
        oco2PriceMessage: oco2Error,
      };
    },
    [validateAskLimitPrice, validateSecondPrice],
  );
  const validateIFOBuyStopSellLimitSellStop = useCallback(
    (ifPrice, oco1Price, oco2Price, ifIsReadOnly = false) => {
      const convertedIfPrice = Number(ifPrice);
      const convertedOco1Price = Number(oco1Price);
      const convertedOco2Price = Number(oco2Price);
      const ifPriceError = ifIsReadOnly ? { isValid: true, errorMessage: '' } : validateAskStopPrice(convertedIfPrice);

      let oco1Error = validateSecondPrice(convertedOco1Price);
      if (oco1Error.isValid && convertedOco1Price <= convertedIfPrice) {
        oco1Error = {
          isValid: false,
          errorMessage: 'OCO1注文の売り指値価格はIF注文の価格より高い価格を設定してください。',
        };
      }
      let oco2Error = validateSecondPrice(convertedOco2Price);
      if (oco2Error.isValid && convertedOco2Price >= convertedIfPrice) {
        oco2Error = {
          isValid: false,
          errorMessage: 'OCO2注文の売り逆指値価格はIF注文の価格より低い価格を設定してください。',
        };
      }
      return {
        ifPriceMessage: ifPriceError,
        oco1PriceMessage: oco1Error,
        oco2PriceMessage: oco2Error,
      };
    },
    [validateAskStopPrice, validateSecondPrice],
  );
  const validateIFOSellLimitBuyLimitBuyStop = useCallback(
    (ifPrice, oco1Price, oco2Price, ifIsReadOnly = false) => {
      const convertedIfPrice = Number(ifPrice);
      const convertedOco1Price = Number(oco1Price);
      const convertedOco2Price = Number(oco2Price);
      const ifPriceError = ifIsReadOnly ? { isValid: true, errorMessage: '' } : validateBidLimitPrice(convertedIfPrice);

      let oco1Error = validateSecondPrice(convertedOco1Price);
      if (oco1Error.isValid && convertedOco1Price >= convertedIfPrice) {
        oco1Error = {
          isValid: false,
          errorMessage: 'OCO1注文の買い指値価格はIF注文の価格より低い価格を設定してください。',
        };
      }

      let oco2Error = validateSecondPrice(convertedOco2Price);
      if (oco2Error && convertedOco2Price <= convertedIfPrice) {
        oco2Error = {
          isValid: false,
          errorMessage: 'OCO2注文の買い逆指値価格はIF注文の価格より高い価格を設定してください。',
        };
      }
      return {
        ifPriceMessage: ifPriceError,
        oco1PriceMessage: oco1Error,
        oco2PriceMessage: oco2Error,
      };
    },
    [validateBidLimitPrice, validateSecondPrice],
  );
  const validateIFOSellStopBuyLimitBuyStop = useCallback(
    (ifPrice, oco1Price, oco2Price, ifIsReadOnly = false) => {
      const convertedIfPrice = Number(ifPrice);
      const convertedOco1Price = Number(oco1Price);
      const convertedOco2Price = Number(oco2Price);
      const ifPriceError = ifIsReadOnly ? { isValid: true, errorMessage: '' } : validateBidStopPrice(convertedIfPrice);

      let oco1Error = validateSecondPrice(convertedOco1Price);
      if (oco1Error.isValid && convertedOco1Price >= convertedIfPrice) {
        oco1Error = {
          isValid: false,
          errorMessage: 'OCO1注文の買い指値価格はIF注文の価格より低い価格を設定してください。',
        };
      }
      let oco2Error = validateSecondPrice(convertedOco2Price);
      if (oco2Error.isValid && convertedOco2Price <= convertedIfPrice) {
        oco2Error = {
          isValid: false,
          errorMessage: 'OCO2注文の買い逆指値価格はIF注文の価格より高い価格を設定してください。',
        };
      }
      return {
        ifPriceMessage: ifPriceError,
        oco1PriceMessage: oco1Error,
        oco2PriceMessage: oco2Error,
      };
    },
    [validateBidStopPrice, validateSecondPrice],
  );

  const validateSettlementTime = useCallback(
    (type, time, settlementType, settlementTime) => {
      const invalidMessage = {
        isValid: false,
        errorMessage: VALIDATION_SETTLEMENT_TIME_ERROR_MESSAGE,
      };
      const validMessage = { isValid: true, errorMessage: '' };
      const firstTime = getTimeValueByExpirationType(type, time, serviceId);
      const secondTime = getTimeValueByExpirationType(settlementType, settlementTime, serviceId);

      if (settlementType === EXPIRATION_TYPE_MAIN.INFINITY.ID) {
        return validMessage;
      }
      if (
        (type === EXPIRATION_TYPE_MAIN.INFINITY.ID && settlementType !== EXPIRATION_TYPE_MAIN.INFINITY.ID) ||
        getTimeSecondsDifference(firstTime, secondTime) > 0
      ) {
        return invalidMessage;
      }
      return validMessage;
    },
    [serviceId],
  );

  return {
    validateQuantity,
    validateFIFOQuantity,
    validateAskLimitPrice,
    validateAskStopPrice,
    validateBidLimitPrice,
    validateBidStopPrice,
    validateTime,
    validateIFDBuyLimitSellLimitPrice,
    validateIFDBuyLimitSellStopPrice,
    validateIFDBuyStopSellLimitPrice,
    validateIFDBuyStopSellStopPrice,
    validateIFDSellLimitBuyLimitPrice,
    validateIFDSellLimitBuyStopPrice,
    validateIFDSellStopBuyLimitPrice,
    validateIFDSellStopBuyStopPrice,
    validateIFOBuyLimitSellLimitSellStop,
    validateIFOBuyStopSellLimitSellStop,
    validateIFOSellLimitBuyLimitBuyStop,
    validateIFOSellStopBuyLimitBuyStop,
    validateSettlementTime,
  };
};

export const useValidateManualTradeInput = () => {
  const dispatch = useDispatch();
  const serviceId = useSelector((state) => state.auth.serviceId);
  const ordersSettings = useSelector((state) => state.manualTrade.createOrders);
  const selectedOrderType = useSelector((state) => state.manualTrade.selectedOrderType);
  const selectedCurrencyId = useSelector((state) => state.manualTrade.selectedInstrumentId[serviceId]);
  const isCrossOrder = useSelector((state) => state.settings[serviceId].isCrossOrder);
  const {
    validateQuantity,
    validateFIFOQuantity,
    validateAskLimitPrice,
    validateAskStopPrice,
    validateBidLimitPrice,
    validateBidStopPrice,
    validateTime,
    validateIFDBuyLimitSellLimitPrice,
    validateIFDBuyLimitSellStopPrice,
    validateIFDBuyStopSellLimitPrice,
    validateIFDBuyStopSellStopPrice,
    validateIFDSellLimitBuyLimitPrice,
    validateIFDSellLimitBuyStopPrice,
    validateIFDSellStopBuyLimitPrice,
    validateIFDSellStopBuyStopPrice,
    validateIFOBuyLimitSellLimitSellStop,
    validateIFOBuyStopSellLimitSellStop,
    validateIFOSellLimitBuyLimitBuyStop,
    validateIFOSellStopBuyLimitBuyStop,
    validateSettlementTime,
  } = useValidateCommonInputByRules(selectedCurrencyId);
  const orderType = useMemo(() => ORDER_TYPES_BY_ID[selectedOrderType], [selectedOrderType]);
  const orderValues = useMemo(() => ordersSettings[orderType], [ordersSettings, orderType]);
  const side = useMemo(() => orderValues[BUY_SELL_INPUT], [orderValues]);
  const isSell = useMemo(() => Number(side) === BUY_SELL_MAIN.SELL.ID, [side]);
  const isBuy = useMemo(() => Number(side) === BUY_SELL_MAIN.BUY.ID, [side]);
  const isIFD = useMemo(() => selectedOrderType === ORDER_TYPES_MAIN.IFD.ID, [selectedOrderType]);
  const isIFO = useMemo(() => selectedOrderType === ORDER_TYPES_MAIN.IFO.ID, [selectedOrderType]);
  const type = useMemo(() => orderValues[ORDER_METHOD_INPUT], [orderValues]);
  const ifPrice = useMemo(() => orderValues[PRICE_INPUT], [orderValues]);
  const donePrice = useMemo(() => orderValues[SETTLEMENT_PRICE_INPUT], [orderValues]);
  const oco1Price = useMemo(() => orderValues[SETTLEMENT_LIMIT_PRICE_INPUT], [orderValues]);
  const oco2Price = useMemo(() => orderValues[SETTLEMENT_STOP_PRICE_INPUT], [orderValues]);
  const settlementType = useMemo(() => orderValues[SETTLEMENT_ORDER_METHOD_INPUT], [orderValues]);
  const selectedDate = useMemo(() => orderValues[DATE_INPUT], [orderValues]);
  const settlementSelectedDate = useMemo(() => orderValues[SETTLEMENT_DATE_INPUT], [orderValues]);
  const selectedTime = useMemo(() => orderValues[TIME_INPUT], [orderValues]);
  const settlementSelectedTime = useMemo(() => orderValues[SETTLEMENT_TIME_INPUT], [orderValues]);
  const selectedExpireType = useMemo(() => orderValues[EXPIRATION_TYPE_INPUT], [orderValues]);
  const settlementSelectedExpireType = useMemo(() => orderValues[SETTLEMENT_EXPIRATION_TYPE_INPUT], [orderValues]);
  return useCallback(
    ({
      inputName,
      value,
      changedType,
      changedSettlementType,
      changedExpireType,
      changedExpireDate,
      changedExpireTime,
      changedSettlementExpireType,
      changedSettlementExpireDate,
      changedSettlementExpireTime,
      marketOrderSelectedSide,
      oppositeSideTotalQuantity,
    }) => {
      if (!marketOrderSelectedSide && selectedOrderType === ORDER_TYPES_MAIN.MARKET_ORDER.ID) return true;
      const isQuantity = inputName === COUNT_INPUT;
      const isIfPrice = inputName === PRICE_INPUT;
      const isDonePrice = inputName === SETTLEMENT_PRICE_INPUT;
      const isOco1Price = inputName === SETTLEMENT_LIMIT_PRICE_INPUT;
      const isOco2Price = inputName === SETTLEMENT_STOP_PRICE_INPUT;
      const isTime = inputName === TIME_INPUT;
      const isDate = inputName === DATE_INPUT;
      const isSettlementTime = inputName === SETTLEMENT_TIME_INPUT;
      const isSettlementDate = inputName === SETTLEMENT_DATE_INPUT;
      const isSettlementExpireType = inputName === SETTLEMENT_EXPIRATION_TYPE_INPUT;
      const isSettlementPrice = SETTLEMENT_PRICE_INPUTS.includes(inputName);
      const isPrice = PRICE_INPUTS.includes(inputName);
      const selectedType = isSettlementPrice ? settlementType : type;
      const ifPriceType = Boolean(changedType) || changedType === 0 ? changedType : Number(type);
      const donePriceType = Boolean(changedType) || changedType === 0 ? changedSettlementType : Number(settlementType);
      const currentChangedType = SETTLEMENT_PRICE_INPUTS.includes(inputName) ? changedSettlementType : changedType;
      const currentType = Boolean(currentChangedType) || currentChangedType === 0 ? currentChangedType : selectedType;
      const currentExpireType =
        Boolean(changedExpireType) || changedExpireType === 0 ? changedExpireType : selectedExpireType;
      const currentSettlementExpireType =
        Boolean(changedSettlementExpireType) || changedSettlementExpireType === 0
          ? changedSettlementExpireType
          : settlementSelectedExpireType;
      const currentExpireDate = changedExpireDate || selectedDate;
      const currentSettlementExpireDate = changedSettlementExpireDate || settlementSelectedDate;
      const currentExpireTime = changedExpireTime || selectedTime;
      const currentSettlementExpireTime = changedSettlementExpireTime || settlementSelectedTime;
      const isLimitPrice = Number(currentType) === ORDER_METHOD_MAIN.LIMIT.ID || LIMIT_PRICE_INPUTS.includes(inputName);
      const isStopPrice =
        Number(currentType) === ORDER_METHOD_MAIN.STOP_LIMIT.ID || STOP_PRICE_INPUTS.includes(inputName);

      if (inputName === EXPIRATION_TYPE_INPUT && value !== EXPIRATION_TYPE_MAIN.CUSTOM.ID) {
        dispatch(
          changeCreateOrderValidationErrors({
            errorMessage: '',
            hasValidationError: false,
            orderType,
            inputName: TIME_INPUT,
          }),
        );
        dispatch(
          changeCreateOrderValidationErrors({
            errorMessage: '',
            hasValidationError: false,
            orderType,
            inputName: DATE_INPUT,
          }),
        );
      }

      if (isSettlementExpireType && value !== EXPIRATION_TYPE_MAIN.CUSTOM.ID) {
        dispatch(
          changeCreateOrderValidationErrors({
            errorMessage: '',
            hasValidationError: false,
            orderType,
            inputName: SETTLEMENT_DATE_INPUT,
          }),
        );
        dispatch(
          changeCreateOrderValidationErrors({
            errorMessage: '',
            hasValidationError: false,
            orderType,
            inputName: SETTLEMENT_TIME_INPUT,
          }),
        );
      }

      if (value === '') {
        dispatch(
          changeCreateOrderValidationErrors({
            errorMessage: VALIDATION_ERROR_EMPTY_FIELD,
            hasValidationError: true,
            orderType,
            inputName,
          }),
        );
        return false;
      }
      if (
        ((isTime || isSettlementTime) && !String(value).match(FULL_TIME_REG)) ||
        (!isTime && !isDate && !isSettlementTime && !isSettlementDate && !String(value).match(NUMBER_VALIDATION_REG))
      ) {
        dispatch(
          changeCreateOrderValidationErrors({
            errorMessage: VALIDATION_ERROR_INVALID_VALUE,
            hasValidationError: true,
            orderType,
            inputName,
          }),
        );
        return false;
      }
      if (isTime) {
        const { isValid, errorMessage } = validateTime(createDateStringByDateAndTime(currentExpireDate, value));
        dispatch(
          changeCreateOrderValidationErrors({
            errorMessage,
            hasValidationError: !isValid,
            orderType,
            inputName,
          }),
        );
        return isValid;
      }
      if (isDate) {
        if (!value) {
          dispatch(
            changeCreateOrderValidationErrors({
              errorMessage: VALIDATION_ERROR_EMPTY_FIELD,
              hasValidationError: true,
              orderType,
              inputName,
            }),
          );
          return false;
        }
        const { isValid, errorMessage } = validateTime(createDateStringByDateAndTime(value, currentExpireTime));
        dispatch(
          changeCreateOrderValidationErrors({
            errorMessage,
            hasValidationError: !isValid,
            orderType,
            inputName,
          }),
        );
        return isValid;
      }
      if (isIFD || isIFO) {
        if (isSettlementExpireType) {
          const { isValid, errorMessage } = validateSettlementTime(
            currentExpireType,
            createDateStringByDateAndTime(currentExpireDate, currentExpireTime),
            value,
            createDateStringByDateAndTime(currentSettlementExpireDate, currentSettlementExpireTime),
          );
          dispatch(
            changeCreateOrderValidationErrors({
              errorMessage,
              hasValidationError: !isValid,
              orderType,
              inputName,
            }),
          );
          return isValid;
        }
        if (isSettlementTime) {
          const { isValid, errorMessage } = validateSettlementTime(
            currentExpireType,
            createDateStringByDateAndTime(currentExpireDate, currentExpireTime),
            currentSettlementExpireType,
            createDateStringByDateAndTime(currentSettlementExpireDate, value),
          );
          dispatch(
            changeCreateOrderValidationErrors({
              errorMessage,
              hasValidationError: !isValid,
              orderType,
              inputName,
            }),
          );
          return isValid;
        }
        if (isSettlementDate) {
          if (!value) {
            dispatch(
              changeCreateOrderValidationErrors({
                errorMessage: VALIDATION_ERROR_EMPTY_FIELD,
                hasValidationError: true,
                orderType,
                inputName,
              }),
            );
            return false;
          }
          const { isValid, errorMessage } = validateSettlementTime(
            currentExpireType,
            createDateStringByDateAndTime(currentExpireDate, currentExpireTime),
            currentSettlementExpireType,
            createDateStringByDateAndTime(value, currentSettlementExpireTime),
          );
          dispatch(
            changeCreateOrderValidationErrors({
              errorMessage,
              hasValidationError: !isValid,
              orderType,
              inputName,
            }),
          );
          return isValid;
        }
      }
      if (isQuantity) {
        let messages = { isValid: false, errorMessage: '' };

        if (selectedOrderType === ORDER_TYPES_MAIN.MARKET_ORDER.ID && !isCrossOrder)
          messages = validateFIFOQuantity(value, marketOrderSelectedSide, oppositeSideTotalQuantity);
        else messages = validateQuantity(value, marketOrderSelectedSide || side);

        const { isValid, errorMessage } = messages;
        dispatch(
          changeCreateOrderValidationErrors({
            errorMessage,
            hasValidationError: !isValid,
            orderType,
            inputName,
          }),
        );
        return isValid;
      }
      if (isPrice) {
        if (isIFD) {
          let messages = {
            ifPriceMessage: { errorMessage: '', isValid: true },
            donePriceMessage: { errorMessage: '', isValid: true },
          };
          if (isBuy && ifPriceType === ORDER_METHOD_MAIN.LIMIT.ID && donePriceType === ORDER_METHOD_MAIN.LIMIT.ID) {
            messages = validateIFDBuyLimitSellLimitPrice(isIfPrice ? value : ifPrice, isDonePrice ? value : donePrice);
          }
          if (
            isBuy &&
            ifPriceType === ORDER_METHOD_MAIN.LIMIT.ID &&
            donePriceType === ORDER_METHOD_MAIN.STOP_LIMIT.ID
          ) {
            messages = validateIFDBuyLimitSellStopPrice(isIfPrice ? value : ifPrice, isDonePrice ? value : donePrice);
          }
          if (
            isBuy &&
            ifPriceType === ORDER_METHOD_MAIN.STOP_LIMIT.ID &&
            donePriceType === ORDER_METHOD_MAIN.LIMIT.ID
          ) {
            messages = validateIFDBuyStopSellLimitPrice(isIfPrice ? value : ifPrice, isDonePrice ? value : donePrice);
          }
          if (
            isBuy &&
            ifPriceType === ORDER_METHOD_MAIN.STOP_LIMIT.ID &&
            donePriceType === ORDER_METHOD_MAIN.STOP_LIMIT.ID
          ) {
            messages = validateIFDBuyStopSellStopPrice(isIfPrice ? value : ifPrice, isDonePrice ? value : donePrice);
          }
          if (isSell && ifPriceType === ORDER_METHOD_MAIN.LIMIT.ID && donePriceType === ORDER_METHOD_MAIN.LIMIT.ID) {
            messages = validateIFDSellLimitBuyLimitPrice(isIfPrice ? value : ifPrice, isDonePrice ? value : donePrice);
          }
          if (
            isSell &&
            ifPriceType === ORDER_METHOD_MAIN.LIMIT.ID &&
            donePriceType === ORDER_METHOD_MAIN.STOP_LIMIT.ID
          ) {
            messages = validateIFDSellLimitBuyStopPrice(isIfPrice ? value : ifPrice, isDonePrice ? value : donePrice);
          }
          if (
            isSell &&
            ifPriceType === ORDER_METHOD_MAIN.STOP_LIMIT.ID &&
            donePriceType === ORDER_METHOD_MAIN.LIMIT.ID
          ) {
            messages = validateIFDSellStopBuyLimitPrice(isIfPrice ? value : ifPrice, isDonePrice ? value : donePrice);
          }
          if (
            isSell &&
            ifPriceType === ORDER_METHOD_MAIN.STOP_LIMIT.ID &&
            donePriceType === ORDER_METHOD_MAIN.STOP_LIMIT.ID
          ) {
            messages = validateIFDSellStopBuyStopPrice(isIfPrice ? value : ifPrice, isDonePrice ? value : donePrice);
          }
          const { ifPriceMessage, donePriceMessage } = messages;
          dispatch(
            changeCreateOrderValidationErrors({
              inputName: PRICE_INPUT,
              errorMessage: ifPriceMessage.errorMessage,
              hasValidationError: !ifPriceMessage.isValid,
              orderType,
            }),
          );
          dispatch(
            changeCreateOrderValidationErrors({
              inputName: SETTLEMENT_PRICE_INPUT,
              errorMessage: donePriceMessage.errorMessage,
              hasValidationError: !donePriceMessage.isValid,
              orderType,
            }),
          );
          return ifPriceMessage.isValid && donePriceMessage.isValid;
        }
        if (isIFO) {
          let messages = {
            ifPriceMessage: { isValid: true, errorMessage: '' },
            oco1PriceMessage: { isValid: true, errorMessage: '' },
            oco2PriceMessage: { isValid: true, errorMessage: '' },
          };
          if (isBuy && ifPriceType === ORDER_METHOD_MAIN.LIMIT.ID) {
            messages = validateIFOBuyLimitSellLimitSellStop(
              isIfPrice ? value : ifPrice,
              isOco1Price ? value : oco1Price,
              isOco2Price ? value : oco2Price,
            );
          }
          if (isBuy && ifPriceType === ORDER_METHOD_MAIN.STOP_LIMIT.ID) {
            messages = validateIFOBuyStopSellLimitSellStop(
              isIfPrice ? value : ifPrice,
              isOco1Price ? value : oco1Price,
              isOco2Price ? value : oco2Price,
            );
          }
          if (isSell && ifPriceType === ORDER_METHOD_MAIN.LIMIT.ID) {
            messages = validateIFOSellLimitBuyLimitBuyStop(
              isIfPrice ? value : ifPrice,
              isOco1Price ? value : oco1Price,
              isOco2Price ? value : oco2Price,
            );
          }
          if (isSell && ifPriceType === ORDER_METHOD_MAIN.STOP_LIMIT.ID) {
            messages = validateIFOSellStopBuyLimitBuyStop(
              isIfPrice ? value : ifPrice,
              isOco1Price ? value : oco1Price,
              isOco2Price ? value : oco2Price,
            );
          }
          const { ifPriceMessage, oco1PriceMessage, oco2PriceMessage } = messages;
          dispatch(
            changeCreateOrderValidationErrors({
              inputName: PRICE_INPUT,
              errorMessage: ifPriceMessage.errorMessage,
              hasValidationError: !ifPriceMessage.isValid,
              orderType,
            }),
          );
          dispatch(
            changeCreateOrderValidationErrors({
              inputName: SETTLEMENT_LIMIT_PRICE_INPUT,
              errorMessage: oco1PriceMessage.errorMessage,
              hasValidationError: !oco1PriceMessage.isValid,
              orderType,
            }),
          );
          dispatch(
            changeCreateOrderValidationErrors({
              inputName: SETTLEMENT_STOP_PRICE_INPUT,
              errorMessage: oco2PriceMessage.errorMessage,
              hasValidationError: !oco2PriceMessage.isValid,
              orderType,
            }),
          );
          return ifPriceMessage.isValid && oco1PriceMessage.isValid && oco2PriceMessage.isValid;
        }
        if (isLimitPrice && isSell) {
          const { isValid, errorMessage } = validateBidLimitPrice(value);
          dispatch(
            changeCreateOrderValidationErrors({ inputName, errorMessage, orderType, hasValidationError: !isValid }),
          );
          return isValid;
        }
        if (isLimitPrice && isBuy) {
          const { isValid, errorMessage } = validateAskLimitPrice(value);
          dispatch(
            changeCreateOrderValidationErrors({ inputName, errorMessage, orderType, hasValidationError: !isValid }),
          );
          return isValid;
        }
        if (isStopPrice && isSell) {
          const { isValid, errorMessage } = validateBidStopPrice(value);
          dispatch(
            changeCreateOrderValidationErrors({ inputName, errorMessage, orderType, hasValidationError: !isValid }),
          );
          return isValid;
        }
        if (isStopPrice && isBuy) {
          const { isValid, errorMessage } = validateAskStopPrice(value);
          dispatch(
            changeCreateOrderValidationErrors({ inputName, errorMessage, orderType, hasValidationError: !isValid }),
          );
          return isValid;
        }
      }
      dispatch(
        changeCreateOrderValidationErrors({
          errorMessage: '',
          hasValidationError: false,
          orderType,
          inputName,
        }),
      );
      return true;
    },
    [
      settlementType,
      type,
      selectedExpireType,
      settlementSelectedExpireType,
      selectedDate,
      settlementSelectedDate,
      selectedTime,
      settlementSelectedTime,
      selectedOrderType,
      isCrossOrder,
      isIFD,
      isIFO,
      dispatch,
      orderType,
      validateTime,
      validateSettlementTime,
      validateQuantity,
      validateFIFOQuantity,
      side,
      isSell,
      isBuy,
      validateIFDBuyLimitSellLimitPrice,
      ifPrice,
      donePrice,
      validateIFDBuyLimitSellStopPrice,
      validateIFDBuyStopSellLimitPrice,
      validateIFDBuyStopSellStopPrice,
      validateIFDSellLimitBuyLimitPrice,
      validateIFDSellLimitBuyStopPrice,
      validateIFDSellStopBuyLimitPrice,
      validateIFDSellStopBuyStopPrice,
      validateIFOBuyLimitSellLimitSellStop,
      oco1Price,
      oco2Price,
      validateIFOBuyStopSellLimitSellStop,
      validateIFOSellLimitBuyLimitBuyStop,
      validateIFOSellStopBuyLimitBuyStop,
      validateBidLimitPrice,
      validateAskLimitPrice,
      validateBidStopPrice,
      validateAskStopPrice,
    ],
  );
};

export const useValidateSelectedManualTradeOrder = () => {
  const { createOrders, selectedOrderType } = useSelector((state) => state.manualTrade);

  const validateManualTradeInput = useValidateManualTradeInput();
  return useCallback(
    (types) => {
      const { marketOrderSelectedSide, oppositeSideTotalQuantity, changedType, settlementChangedType } = types || {};
      return Object.entries(createOrders[ORDER_TYPES_BY_ID[selectedOrderType]])
        .reduce((validationArray, [inputName, value]) => {
          if (
            VALIDATED_INPUTS.includes(inputName) &&
            !(
              ((inputName === DATE_INPUT || inputName === TIME_INPUT) &&
                createOrders[ORDER_TYPES_BY_ID[selectedOrderType]][EXPIRATION_TYPE_INPUT] !==
                  EXPIRATION_TYPE_MAIN.CUSTOM.ID) ||
              ((inputName === SETTLEMENT_DATE_INPUT || inputName === SETTLEMENT_TIME_INPUT) &&
                createOrders[ORDER_TYPES_BY_ID[selectedOrderType]][SETTLEMENT_EXPIRATION_TYPE_INPUT] !==
                  EXPIRATION_TYPE_MAIN.CUSTOM.ID)
            )
          ) {
            validationArray.push(
              validateManualTradeInput({
                inputName,
                value,
                marketOrderSelectedSide,
                oppositeSideTotalQuantity,
                changedType,
                changedSettlementType: settlementChangedType,
              }),
            );
          }
          return validationArray;
        }, [])
        .every((validValue) => validValue);
    },
    [createOrders, selectedOrderType, validateManualTradeInput],
  );
};

export const useManualTradeChangeActiveCurrency = () => {
  const dispatch = useDispatch();
  const serviceId = useSelector((state) => state.auth.serviceId);
  const selectedOrderType = useSelector((state) => state.manualTrade.selectedOrderType);
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const currencyPairs = useSelector((state) => state.currencies.rates);
  const orderSettings = useSelector((state) => state.settings[serviceId].orderSettings);
  const isFX = useMemo(() => serviceId === FX, [serviceId]);

  return useCallback(
    (id) => {
      const pricePrecision = instrumentList?.[id]?.pricePrecision ?? 0;
      dispatch(changeTradeSelectedInstrumentId({ id, serviceId }));
      const refreshTabOrderActions = getRefreshStateActionsArrayForManualTrade({
        orderType: ORDER_TYPES_BY_ID[selectedOrderType],
        sellPrice: isFX
          ? currencyPairs?.[id]?.bid
          : Number(roundToFixedPrecision(currencyPairs?.[id]?.bid, pricePrecision)),
        buyPrice: isFX
          ? currencyPairs?.[id]?.ask
          : Number(roundToFixedPrecision(currencyPairs?.[id]?.ask, pricePrecision)),
        orderSettings,
        instrumentId: id,
      });
      batch(() => {
        refreshTabOrderActions.forEach((action) => dispatch(action));
      });
      saveDefaultValuesFromLocalStorage({ key: KEY_FOR_DEFAULT_SELECTED_INSTRUMENT_ID[serviceId], value: id });
    },
    [dispatch, currencyPairs, orderSettings, selectedOrderType, serviceId, isFX, instrumentList],
  );
};

export const useInputCreateOrder = (orderType, inputName, precision, isCommon = false, allowUserDecimals = false) => {
  const dispatch = useDispatch();
  const orderData = useSelector((state) => state.manualTrade.createOrders[orderType]);
  const selectedOrderType = useSelector((state) => state.manualTrade.selectedOrderType);

  const roundValue = useCallback(
    (val) => {
      const shouldBeRounded = precision && NUMBER_VALIDATION_REG.test(String(val)) && !allowUserDecimals;
      return shouldBeRounded ? roundExactlyOnPrecisionMatching(val, Math.round(1 / precision)) : val;
    },
    [precision, allowUserDecimals],
  );

  const value = roundValue(orderData[inputName]);

  const validateManualTradeInput = useValidateManualTradeInput();

  const onChange = useCallback(
    (inputValue) => {
      const isIFD = selectedOrderType === ORDER_TYPES_MAIN.IFD.ID;
      const isIFO = selectedOrderType === ORDER_TYPES_MAIN.IFO.ID;

      if (VALIDATED_INPUTS.includes(inputName)) {
        validateManualTradeInput({
          inputName,
          value:
            precision && inputValue !== ''
              ? roundExactlyOnPrecisionMatching(inputValue, Math.round(1 / precision))
              : inputValue,
        });

        if (DATE_INPUTS.includes(inputName) || TIME_INPUTS.includes(inputName)) {
          validateManualTradeInput({
            inputName: ADDITION_VALIDATION_DATE_INPUTS[inputName],
            value: orderData[ADDITION_VALIDATION_DATE_INPUTS[inputName]],
            [CHANGED_DATE_VALUES[inputName]]: inputValue,
          });
        }

        if (isIFD || isIFO) {
          if (VALIDATION_DATE_INPUTS.includes(inputName)) {
            if (orderData[SETTLEMENT_EXPIRATION_TYPE_INPUT] === EXPIRATION_TYPE_MAIN.CUSTOM.ID) {
              VALIDATION_SETTLEMENT_DATE_INPUTS.forEach((validationInputName) => {
                validateManualTradeInput({
                  inputName: validationInputName,
                  value: orderData[validationInputName],
                  [CHANGED_DATE_VALUES[inputName]]: inputValue,
                });
              });
            } else {
              validateManualTradeInput({
                inputName: SETTLEMENT_EXPIRATION_TYPE_INPUT,
                value: orderData[SETTLEMENT_EXPIRATION_TYPE_INPUT],
                [CHANGED_DATE_VALUES[inputName]]: inputValue,
              });
            }
          }

          if (VALIDATION_SETTLEMENT_DATE_INPUTS.includes(inputName)) {
            const settlementExpirationTypeInputValue =
              inputName === SETTLEMENT_EXPIRATION_TYPE_INPUT ? inputValue : orderData[SETTLEMENT_EXPIRATION_TYPE_INPUT];
            const settlementExpirationTimeInputValue =
              inputName === SETTLEMENT_TIME_INPUT ? inputValue : orderData[SETTLEMENT_TIME_INPUT];
            const settlementExpirationDateInputValue =
              inputName === SETTLEMENT_DATE_INPUT ? inputValue : orderData[SETTLEMENT_DATE_INPUT];

            if (settlementExpirationTypeInputValue === EXPIRATION_TYPE_MAIN.CUSTOM.ID) {
              validateManualTradeInput({
                inputName: SETTLEMENT_EXPIRATION_TYPE_INPUT,
                value: settlementExpirationTypeInputValue,
                [CHANGED_DATE_VALUES[inputName]]: inputValue,
              });

              validateManualTradeInput({
                inputName: SETTLEMENT_TIME_INPUT,
                value: settlementExpirationTimeInputValue,
                [CHANGED_DATE_VALUES[inputName]]: inputValue,
              });

              validateManualTradeInput({
                inputName: SETTLEMENT_DATE_INPUT,
                value: settlementExpirationDateInputValue,
                [CHANGED_DATE_VALUES[inputName]]: inputValue,
              });
            } else {
              validateManualTradeInput({
                inputName: SETTLEMENT_EXPIRATION_TYPE_INPUT,
                value: settlementExpirationTypeInputValue,
                [CHANGED_DATE_VALUES[inputName]]: inputValue,
              });
            }
          }
        }
      }

      if (isCommon) {
        const currentDate = new Date(getRoundedPlusOneHourCurrentDate());
        const timeValue = getRoundedPlusOneHourCurrentTime();

        Object.values(ORDER_TYPES).forEach((type) => {
          if (Object.values(type.inputs).includes(inputName)) {
            dispatch(
              changeCreateOrderValues({
                orderType: type.name,
                inputName,
                value:
                  precision && String(inputValue).match(NUMBER_VALIDATION_REG) && !allowUserDecimals
                    ? roundExactlyOnPrecisionMatching(inputValue, Math.round(1 / precision))
                    : inputValue,
              }),
            );
            if (inputName === EXPIRATION_TYPE_INPUT) {
              dispatch(
                changeCreateOrderValues({
                  orderType: type.name,
                  inputName: DATE_INPUT,
                  value: currentDate,
                }),
              );
              dispatch(
                changeCreateOrderValues({
                  orderType: type.name,
                  inputName: TIME_INPUT,
                  value: timeValue,
                }),
              );
              dispatch(
                changeCreateOrderValidationErrors({
                  errorMessage: '',
                  hasValidationError: false,
                  orderType: type.name,
                  inputName: TIME_INPUT,
                }),
              );
              if (isIFD || isIFO) {
                // ifd, ifo の場合は自動補完された値に対して決済側のバリデーションを走らせる
                [
                  [DATE_INPUT, currentDate],
                  [TIME_INPUT, timeValue],
                ].forEach(([completedInputName, completedValue]) => {
                  if (orderData[SETTLEMENT_EXPIRATION_TYPE_INPUT] === EXPIRATION_TYPE_MAIN.CUSTOM.ID) {
                    VALIDATION_SETTLEMENT_DATE_INPUTS.forEach((validationInputName) => {
                      validateManualTradeInput({
                        inputName: validationInputName,
                        value: orderData[validationInputName],
                        [CHANGED_DATE_VALUES[completedInputName]]: completedValue,
                      });
                    });
                  } else {
                    validateManualTradeInput({
                      inputName: SETTLEMENT_EXPIRATION_TYPE_INPUT,
                      value: orderData[SETTLEMENT_EXPIRATION_TYPE_INPUT],
                      [CHANGED_DATE_VALUES[completedInputName]]: completedValue,
                    });
                  }
                });
              }
            }
          }
        });
      } else {
        dispatch(
          changeCreateOrderValues({
            orderType,
            inputName,
            value: roundValue(inputValue),
          }),
        );
      }
    },
    [
      dispatch,
      inputName,
      isCommon,
      orderData,
      orderType,
      precision,
      roundValue,
      selectedOrderType,
      validateManualTradeInput,
      allowUserDecimals,
    ],
  );

  return [value, onChange];
};

export const useBuySellInputCreateOrder = (orderType) => {
  const dispatch = useDispatch();
  const value = useSelector((state) => state.manualTrade.createOrders[orderType][BUY_SELL_INPUT]);
  const serviceId = useSelector((state) => state.auth.serviceId);
  const selectedCurrencyId = useSelector((state) => state.manualTrade.selectedInstrumentId[serviceId]);

  const selectedSide = useSelector((state) => state.manualTrade.selectedSide);

  const { sellPrice, buyPrice } = usePricesForBuySell({
    currencyPair: selectedCurrencyId,
  });

  const onChange = useCallback(
    (buySellId) => {
      dispatch(
        changeChartSide({
          id: BUY_SELL_CHART_ID_BY_ID[buySellId],
        }),
      );

      if (BUY_SELL_CHART_ID_BY_ID[buySellId] === selectedSide) {
        return;
      }
      saveDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_SELECT_SIDE,
        value: BUY_SELL_CHART_ID_BY_ID[buySellId],
      });

      const changeOrderActions = getChangeManualTradeOrderValuesActionsArray(orderType, buySellId, sellPrice, buyPrice);

      batch(() => {
        Object.values(ORDER_TYPES).forEach(({ name }) =>
          dispatch(
            changeCreateOrderValues({
              orderType: name,
              inputName: BUY_SELL_INPUT,
              value: buySellId,
            }),
          ),
        );
        dispatch(
          changeSelectedSide({
            id: BUY_SELL_CHART_ID_BY_ID[buySellId],
          }),
        );
        changeOrderActions.forEach((action) => dispatch(action));
      });
    },
    [dispatch, orderType, sellPrice, buyPrice, selectedSide],
  );
  return [value, onChange];
};

export const useCalculatingSimulationChartData = ({ strategyList }) => {
  return useMemo(() => {
    if (!strategyList || !strategyList.length) {
      return { chartData: [], chartStats: {} };
    }

    const chartData = _(strategyList)
      .reduce(
        (reducedCharts, strategy) =>
          _(reducedCharts).mergeWith(
            // multiply p&l with strategy_sets
            _(strategy.simulationChartList)
              .map((item) => ({
                date: item.targetDate,
                realizedPl: new Decimal(item.realizedPl),
                upl: new Decimal(item.unrealizedPl),
              }))
              .keyBy('date')
              .value(),
            (obj, src) => {
              if (obj === undefined || src === undefined) {
                const v = obj || src;
                if (!v) return v;
                return {
                  date: v.date,
                  realizedPl: new Decimal(v.realizedPl),
                  upl: new Decimal(v.upl),
                };
              }
              return {
                date: src.date,
                realizedPl: new Decimal(src.realizedPl).add(obj.realizedPl),
                upl: new Decimal(src.upl).add(obj.upl),
              };
            },
          ),
        {},
      )
      .values()
      .sortBy('date')
      .value();

    const stats = {
      totalPl: new Decimal(0),
      maxTotalPl: new Decimal(0),
      realizedPl: new Decimal(0),
      unrealizedPl: new Decimal(0),
      minUnrealizedPl: new Decimal(0),
      maxUnrealizedPl: new Decimal(0),
      marginRequired: new Decimal(0),
      maxDd: new Decimal(0),
      marginRecommended: new Decimal(0),
      roi: 0,
      riskReturn: 0,
    };

    _(chartData).forEach((chart) => {
      stats.realizedPl = stats.realizedPl.add(chart.realizedPl);
      stats.unrealizedPl = chart.upl;
      stats.totalPl = stats.realizedPl.add(stats.unrealizedPl);
      chart.pl = new Decimal(stats.realizedPl); // eslint-disable-line no-param-reassign
      chart.sum = chart.pl.add(chart.upl); // eslint-disable-line no-param-reassign
      stats.maxTotalPl = stats.maxTotalPl.comparedTo(chart.sum) > 0 ? stats.maxTotalPl : chart.sum;
      const dd = stats.maxTotalPl.sub(chart.sum);
      stats.maxDd = stats.maxDd.comparedTo(dd) > 0 ? stats.maxDd : dd;
      stats.minUnrealizedPl = stats.minUnrealizedPl.comparedTo(chart.upl) < 0 ? stats.minUnrealizedPl : chart.upl;
      stats.maxUnrealizedPl = stats.maxUnrealizedPl.comparedTo(chart.upl) > 0 ? stats.maxUnrealizedPl : chart.upl;
    });

    chartData.forEach((chartDataItem) => {
      Object.keys(chartDataItem).forEach((chartKey) => {
        if (chartDataItem[chartKey] instanceof Decimal) {
          // eslint-disable-next-line no-param-reassign
          chartDataItem[chartKey] = chartDataItem[chartKey].toNumber();
        }
      });
    });
    return chartData;
  }, [strategyList]);
};

// todo: move to store when rebuild redux structure
export const useCalculateUnrealizedProfitLossForPortfolioCard = ({
  instrumentId,
  apGroupId,
  buySell = '-',
  defaultValue = '-',
  apGroupIdList = [],
  isTechCalc = false,
}) => {
  const unrealizedProfitLossByInstrumentId = useSelector(
    (state) => state.currencies.positionsUnrealizedProfitLoss[instrumentId],
  );

  const calculatedResult = useMemo(
    () =>
      calculateUnrealisedProfitLoss(unrealizedProfitLossByInstrumentId, apGroupId, buySell, apGroupIdList, isTechCalc),
    [unrealizedProfitLossByInstrumentId, apGroupId, buySell, apGroupIdList, isTechCalc],
  );

  return calculatedResult || defaultValue;
};

export const useCalculateQuantityForPortfolioCard = ({
  instrumentId,
  apGroupId,
  buySell = '-',
  defaultValue = '-',
  apGroupIdList = [],
  isTechCalc = false,
}) => {
  const unrealizedProfitLossByInstrumentId = useSelector(
    (state) => state.currencies.positionsUnrealizedProfitLoss[instrumentId],
  );

  const calculatedQuantityResult = useMemo(
    () => calculateQuantity(unrealizedProfitLossByInstrumentId, apGroupId, buySell, apGroupIdList, isTechCalc),
    [unrealizedProfitLossByInstrumentId, apGroupId, buySell, apGroupIdList, isTechCalc],
  );

  return calculatedQuantityResult || defaultValue;
};

export const useCreatePortfolioCard = ({ apGroups, positions }) => {
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const groupedPositions = useMemo(
    () =>
      positions.reduce((groupedPos, positionValue) => {
        if (!positionValue) {
          return groupedPos;
        }
        const { instrumentId, side, quantity, pl, tradeMethod, apGroupId, execTime } = positionValue;
        const findTradeMethodById = (id) => {
          return OPTIONS_TRADE_METHOD_TYPES.find((method) => method.id === Number(id));
        };
        if (findTradeMethodById(tradeMethod).name === TRADE_METHODS.MANUAL.NAME) {
          const tradeId = groupedPos.findIndex((el) => el.instrumentId === instrumentId && el.side === side);
          if (tradeId !== -1) {
            // eslint-disable-next-line no-param-reassign
            groupedPos[tradeId] = {
              ...groupedPos[tradeId],
              quantity: groupedPos[tradeId].quantity + quantity,
              pl: groupedPos[tradeId].pl + pl,
              execTime: groupedPos[tradeId].execTime < execTime ? execTime : groupedPos[tradeId].execTime,
            };
          } else groupedPos.push({ instrumentId, side, quantity, pl, tradeMethod, apGroupId, execTime });
        }
        return groupedPos;
      }, []),
    [positions],
  );

  return useMemo(
    () => [
      ...apGroups.map((ap, index) => ({
        id: ap.id,
        key: `ap - ${index}`,
        type:
          ap.sourceType === AP_GROUP_SOURCES.MONEY_HATCH.KEY
            ? AP_GROUP_SOURCES.MONEY_HATCH.NAME
            : AP_GROUP_SOURCES.SELECT.NAME,
        serviceId: getServiceId(ap.instrumentId, instrumentList),
        currency: ap.instrumentId,
        buySell: '-',
        groupName: ap.name,
        count: ap.positionQuantity || '-',
        realizedProfitLoss: ap.totalRealizedPnl || '-',
        creationTime: ap.entryDate ? new Date(ap.entryDate).toLocaleDateString('ja') : '-',
        totalCount: ap.totalApCount || 0,
        activeCount: ap.activeApCount || 0,
        entryDateTime: ap.entryDateTime,
        sourceType: ap.sourceType,
        parentId: ap.parentId,
        parentName: ap.parentName,
        parentImage: ap.parentImage,
        status: ap.status,
        operationId: ap.operationId,
      })),
      ...groupedPositions.map((position, index) => ({
        key: `position - ${index}`,
        type: MANUAL_POSITION_GROUP_TYPE,
        serviceId: getServiceId(position.instrumentId, instrumentList),
        currency: position.instrumentId,
        buySell: position.side,
        groupName: '-',
        count: position.quantity.toFixed(1),
        realizedProfitLoss: '-',
        entryDateTime: position.execTime,
      })),
    ],
    [apGroups, groupedPositions, instrumentList],
  );
};

// helpers for useFilteringPortfolioCard
const descendingSorting = (a, b, sortByKey) => {
  if (a[sortByKey] < b[sortByKey]) {
    return 1;
  }
  if (a[sortByKey] > b[sortByKey] || b[sortByKey] === '-') {
    return -1;
  }
  return 0;
};

const checkType = (currentType, card) => {
  switch (currentType) {
    case TRADE_TYPES.ALL_TYPES.KEY:
      return true;
    case TRADE_TYPES.AUTO.KEY:
      return card.type !== MANUAL_POSITION_GROUP_TYPE && card.type !== AP_GROUP_SOURCES.MONEY_HATCH.NAME;
    case TRADE_TYPES.TRADE.KEY:
      return card.type === MANUAL_POSITION_GROUP_TYPE;
    case TRADE_TYPES.TRADE_SELL.KEY:
      return card.type === MANUAL_POSITION_GROUP_TYPE && Number(card.buySell) === BUY_SELL_MAIN.SELL.ID;
    case TRADE_TYPES.TRADE_BUY.KEY:
      return card.type === MANUAL_POSITION_GROUP_TYPE && Number(card.buySell) === BUY_SELL_MAIN.BUY.ID;
    case TRADE_TYPES.MONEY_HATCH.KEY:
      return card.type === AP_GROUP_SOURCES.MONEY_HATCH.NAME;
    default:
      return false;
  }
};

export const createAssetFilter = (currentType) => (card) => {
  switch (currentType?.value) {
    case ASSET_TYPES.NONE.KEY:
      return true;
    case ASSET_TYPES.FX.KEY:
      return card.serviceId === ASSET_TYPES.FX.KEY;
    case ASSET_TYPES.ETF.KEY:
      return card.serviceId === ASSET_TYPES.ETF.KEY;
    case ASSET_TYPES.CFD.KEY:
      return card.serviceId === ASSET_TYPES.CFD.KEY;
    default:
      return false;
  }
};

export const createTypeFilter = (currentType) => (card) => {
  switch (currentType?.value) {
    case TRADE_TYPES.ALL_TYPES.KEY:
      return true;
    case TRADE_TYPES.AUTO.KEY:
      return card.type !== MANUAL_POSITION_GROUP_TYPE && card.type !== AP_GROUP_SOURCES.MONEY_HATCH.NAME;
    case TRADE_TYPES.TRADE.KEY:
      return card.type === MANUAL_POSITION_GROUP_TYPE;
    case TRADE_TYPES.TRADE_SELL.KEY:
      return card.type === MANUAL_POSITION_GROUP_TYPE && Number(card.buySell) === BUY_SELL_MAIN.SELL.ID;
    case TRADE_TYPES.TRADE_BUY.KEY:
      return card.type === MANUAL_POSITION_GROUP_TYPE && Number(card.buySell) === BUY_SELL_MAIN.BUY.ID;
    default:
      return false;
  }
};

function currencyPairIndexSorting(a, b, currencyPairs) {
  if (a.currency === b.currency) return 0; // if are equal, index would be the same
  for (let i = 0; i < currencyPairs.length; i += 1) {
    if (currencyPairs[i].value === a.currency) return -1; // We don't need to know what other index is
    if (currencyPairs[i].value === b.currency) return 1; // We know it's higher, so we do early exit
  }
  return 0;
}

const profitLossSorting = (a, b, posUplRef, currencyPairs) => {
  const posUPL = posUplRef.current.positionsUnrealizedProfitLoss;
  let aPl;
  if (a?.technicalBuilderId) {
    aPl =
      a?.apGroupIdList?.reduce(
        (accumulator, apGroupId) =>
          calculateUnrealisedProfitLoss(posUPL?.[a.currency], apGroupId, a.parameters.buySellSignType) + accumulator,
        0,
      ) ?? 0;
  } else if (a?.children?.length) {
    aPl = a.children.reduce(
      (accumulator, child) =>
        calculateUnrealisedProfitLoss(posUPL?.[child.currency], child.id, child.buySell) + accumulator,
      0,
    );
  } else {
    aPl = calculateUnrealisedProfitLoss(posUPL?.[a.currency], a.id, a.buySell);
  }
  let bPl;
  if (b?.technicalBuilderId) {
    bPl =
      b?.apGroupIdList?.reduce(
        (accumulator, apGroupId) =>
          calculateUnrealisedProfitLoss(posUPL?.[b.currency], apGroupId, b.parameters.buySellSignType) + accumulator,
        0,
      ) ?? 0;
  } else if (b?.children?.length) {
    bPl = b.children.reduce(
      (accumulator, child) =>
        calculateUnrealisedProfitLoss(posUPL?.[child.currency], child.id, child.buySell) + accumulator,
      0,
    );
  } else {
    bPl = calculateUnrealisedProfitLoss(posUPL?.[b.currency], b.id, b.buySell);
  }

  if ((!aPl && bPl) || (aPl && bPl && aPl < bPl)) {
    return 1;
  }

  if ((aPl && !bPl) || (aPl && bPl && aPl > bPl)) {
    return -1;
  }

  return currencyPairIndexSorting(a, b, currencyPairs);
};

const PORTFOLIO_CARDS_SORTING_FUNCTIONS = {
  [EXEC_TIME_SORTING]: (a, b) => descendingSorting(a, b, 'entryDateTime'),
  [QUANTITY_SORTING]: (a, b) => {
    const countA = Number(a.count);
    const countB = Number(b.count);
    const isNanCountA = Number.isNaN(countA);
    const isNanCountB = Number.isNaN(countB);

    // Non numbers are pushed to the end
    if (isNanCountA && isNanCountB) return 0;
    if (isNanCountA) return 1;
    if (isNanCountB) return -1;

    return countB - countA; // Descending order
  },
};

const isMatchingInstrumentFilter = (instrumentTypeFilter, card) =>
  Array.isArray(instrumentTypeFilter) && instrumentTypeFilter.find((item) => item.value === card.currency);

const isMatchingStatusFilter = (statusType, card) => {
  const getOpenPositions = !card.positionQuantity ? 0 : Number(card.positionQuantity);
  const countValue =
    card.type === MANUAL_POSITION_GROUP_TYPE ? Number(card.count) : card.activeApCount + getOpenPositions;

  switch (statusType) {
    case AP_GROUP_STATUSES.ALL_STATUSES:
      return card;
    case AP_GROUP_STATUSES.ACTIVE:
      return Boolean(countValue);
    case AP_GROUP_STATUSES.INACTIVE:
      return !card.activeApCount && card.type !== MANUAL_POSITION_GROUP_TYPE;
    default:
      return card;
  }
};

export const createStatusFilter = (statusType) => (card) => {
  const getOpenPositions = !card.count || card.count === '-' ? 0 : Number(card.count);
  const countValue =
    card.type === MANUAL_POSITION_GROUP_TYPE ? Number(card.count) : card.activeCount + getOpenPositions;

  switch (statusType?.value) {
    case AP_GROUP_STATUSES.ALL_STATUSES:
      return card;
    case AP_GROUP_STATUSES.ACTIVE:
      return Boolean(countValue) || card.status === TECH_STATUS.ACTIVE;
    case AP_GROUP_STATUSES.INACTIVE:
      return (
        (card.sourceType !== AP_GROUP_SOURCES.TECH.KEY &&
          !card.activeCount &&
          card.type !== MANUAL_POSITION_GROUP_TYPE) ||
        (card.sourceType === AP_GROUP_SOURCES.TECH.KEY && card.status === TECH_STATUS.INACTIVE)
      );
    default:
      return card;
  }
};

export const createGroupStatusFilter = (statusType) => {
  const cardFilter = createStatusFilter(statusType);

  if (statusType?.value === AP_GROUP_STATUSES.ACTIVE)
    return (group) => group.children && group.children.some((child) => cardFilter(child));

  if (statusType?.value === AP_GROUP_STATUSES.INACTIVE)
    return (group) => group.children && group.children.every((child) => cardFilter(child));

  return () => true;
};

export const usePortfolioSort = ({ cardList, sortBy }) => {
  const positionsUnrealizedProfitLoss = useSelector((state) => state.currencies.positionsUnrealizedProfitLoss);
  const positionsUnrealizedProfitLossRef = useRef({});

  useEffect(() => {
    if (positionsUnrealizedProfitLossRef?.current) {
      positionsUnrealizedProfitLossRef.current = { positionsUnrealizedProfitLoss };
    }
  }, [positionsUnrealizedProfitLoss]);

  const instrumentOptions = useInstrumentOptions();
  const currencyPairsOptions = useMemo(
    () => Object.values(instrumentOptions).flatMap((options) => options),
    [instrumentOptions],
  );

  const profitLossSortFunction = useCallback(
    (a, b) => profitLossSorting(a, b, positionsUnrealizedProfitLossRef, currencyPairsOptions),
    [currencyPairsOptions],
  );

  const sortFunction = PORTFOLIO_CARDS_SORTING_FUNCTIONS[sortBy.value] || profitLossSortFunction;

  return cardList.sort(sortFunction);
};

export const useFilteringPortfolioCard = ({
  cardList,
  cardTypeFilter,
  instrumentTypeFilter,
  statusTypeFilter,
  sortTypeFilter,
}) => {
  const positionsUnrealizedProfitLoss = useSelector((state) => state.currencies.positionsUnrealizedProfitLoss);
  const positionsUnrealizedProfitLossRef = useRef({});

  useEffect(() => {
    if (positionsUnrealizedProfitLossRef?.current) {
      positionsUnrealizedProfitLossRef.current = { positionsUnrealizedProfitLoss };
    }
  }, [positionsUnrealizedProfitLoss]);

  const currencyPairsOptions = useSelector(currencyPairsOptionsSelector);

  const apGroupsData = useSelector(apGroupsSelector);

  const profitLossSortFunction = useCallback(
    (a, b) => profitLossSorting(a, b, positionsUnrealizedProfitLossRef, currencyPairsOptions),
    [currencyPairsOptions],
  );

  const sortFunction = PORTFOLIO_CARDS_SORTING_FUNCTIONS[sortTypeFilter] || profitLossSortFunction;

  return useMemo(() => {
    return cardList
      .filter((card) => {
        const isMatchingTradeFilter = checkType(cardTypeFilter, card);
        const isAllBrand = instrumentTypeFilter === ALL_BRAND_OPTION.value;
        const getAutoTradeCard = apGroupsData.find((item) => item.id === card.id);
        const matchCard = card.type === MANUAL_POSITION_GROUP_TYPE ? card : getAutoTradeCard;

        return (
          isMatchingTradeFilter &&
          (isAllBrand || isMatchingInstrumentFilter(instrumentTypeFilter, card)) &&
          isMatchingStatusFilter(statusTypeFilter, matchCard)
        );
      })
      .sort(sortFunction);
  }, [cardList, instrumentTypeFilter, cardTypeFilter, statusTypeFilter, sortFunction, apGroupsData]);
};

export const createInstrumentFilter = (instrumentTypeFilter) => (card) =>
  instrumentTypeFilter === ALL_BRAND_OPTION.value ||
  (Array.isArray(instrumentTypeFilter) &&
    instrumentTypeFilter.find((item) => item.value === removeSuffix(card.currency)));

export const useOrderValidationErrors = (orderType) => {
  const validationMessages = useSelector((state) => state.manualTrade.createOrdersValidationErrors[orderType]);
  return useMemo(
    () =>
      Object.entries(validationMessages).reduce((messages, [inputName, info]) => {
        if (info.hasValidationError) {
          messages.push({ inputName, errorMessage: info.errorMessage });
        }
        return messages;
      }, []),
    [validationMessages],
  );
};

export { useDebounce } from 'use-debounce';
export { useDebouncedCallback } from 'use-debounce';

export const useProfitLossExecutionsChartData = () => {
  const profitLossExecutionsData = useSelector((state) => state.portfolio.profitLossExecutionsData);

  return useMemo(() => {
    const { result } = profitLossExecutionsData.reduce(
      (acc, { pl, execTime }) => {
        const newAcc = { ...acc };
        if (pl) {
          newAcc.totalPl += pl;
          newAcc.result.push({ pl: newAcc.totalPl, date: execTime });
        }
        return newAcc;
      },
      { result: [], totalPl: 0 },
    );

    return result;
  }, [profitLossExecutionsData]);
};

export const useChangeOrderSettingsCount = (setCount, setCountError, serviceId) => {
  return useCallback(
    (value) => {
      setCountError(validateOrderSettingsCount(value, serviceId));
      setCount(
        String(value).match(NUMBER_VALIDATION_REG)
          ? roundExactlyOnPrecisionMatching(value, Math.round(1 / QUANTITY_PRECISION))
          : value,
      );
    },
    [setCount, setCountError, serviceId],
  );
};

export const useCalculatingChartDataWithLabels = ({ strategyList, defaultSets } = {}) => {
  const { chartData, chartStats } = useCalculatingChartData({ strategyList, defaultSets });

  const { marginRecommended, marginRecommendedFX, marginRecommendedETF, marginRecommendedCFD } = chartStats;

  const roundedMarginRecommended = useMemo(
    () => (marginRecommended ? roundUpBy1000(new Decimal(marginRecommended)) : 0),
    [marginRecommended],
  );
  const roundedMarginRecommendedFX = useMemo(
    () => (marginRecommendedFX ? roundUpBy1000(new Decimal(marginRecommendedFX)) : 0),
    [marginRecommendedFX],
  );
  const roundedMarginRecommendedETF = useMemo(
    () => (marginRecommendedETF ? roundUpBy1000(new Decimal(marginRecommendedETF)) : 0),
    [marginRecommendedETF],
  );
  const roundedMarginRecommendedCFD = useMemo(
    () => (marginRecommendedCFD ? roundUpBy1000(new Decimal(marginRecommendedCFD)) : 0),
    [marginRecommendedCFD],
  );

  const roi = useMemo(
    () =>
      marginRecommended > 0
        ? Number(new Decimal(chartStats.totalPl).mul(100).div(roundedMarginRecommended).toFixed(2, Decimal.ROUND_UP))
        : new Decimal(0),
    [chartStats.totalPl, marginRecommended, roundedMarginRecommended],
  );

  const recommendedMarginTooltipForService = useCallback(() => {
    if (!strategyList) return '';

    const serviceId = Array.from(new Set(strategyList.map((strategy) => strategy.strategyDetail.serviceId)) ?? []);

    return recommendedMarginTooltips(serviceId);
  }, [strategyList]);

  return useMemo(
    () => ({
      chartRoi: {
        label: '収益率',
        value: roi,
      },
      totalPl: {
        label: '総合損益',
        value: chartStats.totalPl,
      },
      chartRiskReturn: {
        label: 'リスクリターン評価',
        value: chartStats.riskReturn,
      },
      marginRecommended: {
        label: RECOMMENDED_MARGIN_LABEL,
        value: marginRecommended,
        tooltip: recommendedMarginTooltipForService(),
      },
      marginRequired: {
        label: REQUIRED_MARGIN_LABEL,
        value: chartStats.marginRequired,
        tooltip: REQUIRED_MARGIN_TOOLTIP,
      },
      warningButton: {
        label: '注意事項',
      },
      marginRequiredFX: {
        label: REQUIRED_MARGIN_LABEL,
        value: chartStats.marginRequiredFX,
      },
      marginRequiredETF: {
        label: REQUIRED_MARGIN_LABEL,
        value: chartStats.marginRequiredETF,
      },
      marginRequiredCFD: {
        label: REQUIRED_MARGIN_LABEL,
        value: chartStats.marginRequiredCFD,
      },
      roundedMarginRecommended: {
        label: RECOMMENDED_MARGIN_LABEL,
        value: roundedMarginRecommended,
        tooltip: recommendedMarginTooltipForService(),
      },
      roundedMarginRecommendedFX: {
        label: RECOMMENDED_MARGIN_LABEL,
        value: roundedMarginRecommendedFX,
      },
      roundedMarginRecommendedETF: {
        label: RECOMMENDED_MARGIN_LABEL,
        value: roundedMarginRecommendedETF,
      },
      roundedMarginRecommendedCFD: {
        label: RECOMMENDED_MARGIN_LABEL,
        value: roundedMarginRecommendedCFD,
      },
      chartData,
    }),
    [
      chartData,
      chartStats.marginRequired,
      chartStats.marginRequiredFX,
      chartStats.marginRequiredETF,
      chartStats.marginRequiredCFD,
      marginRecommended,
      roundedMarginRecommended,
      roundedMarginRecommendedFX,
      roundedMarginRecommendedETF,
      roundedMarginRecommendedCFD,
      chartStats.riskReturn,
      roi,
      chartStats.totalPl,
      recommendedMarginTooltipForService,
    ],
  );
};

export const useChangeService = () => {
  const dispatch = useDispatch();
  const accountInfo = useAccountInfo();
  const serviceId = useSelector((state) => state.auth.serviceId);

  const servicesOptions = useMemo(() => {
    return ALL_SERVICES.map((service) => {
      return {
        label: getServiceName(service),
        id: service,
        isVisuallyDisabled: accountInfo[service].isNotAvailable,
      };
    });
  }, [accountInfo]);

  const handleChangeServiceId = useCallback(
    (id) => {
      if (serviceId === id) {
        return;
      }
      dispatch(changeServiceIdRequest({ serviceId: id }));
    },
    [dispatch, serviceId],
  );

  return useMemo(
    () => ({
      options: servicesOptions,
      activeServiceId: serviceId,
      onChange: handleChangeServiceId,
    }),
    [servicesOptions, serviceId, handleChangeServiceId],
  );
};

export const useServiceUnavailableCheck = (serviceId) => {
  const accountInfo = useAccountInfo();
  // serviceId が不正の場合は利用不可に倒しておく
  return accountInfo[serviceId]?.isNotAvailable ?? true;
};

export function useToInstrumentPrecision(instrumentId, placeholder = null) {
  const instrumentMap = useSelector((state) => state.settings.instrumentList);
  const pricePrecision = instrumentMap[instrumentId]?.pricePrecision;

  return useCallback(
    (value) => toCommaSeparatedFixedValue(value, pricePrecision, placeholder),
    [pricePrecision, placeholder],
  );
}

export const usePortfolioCardsFilter = (filteringAssetType, instrumentOptions) => {
  const allAssetsSelected = filteringAssetType === ASSET_TYPES.NONE.KEY;
  const accountInfo = useAccountInfo();
  const selectedInstrumentsOptions = useMemo(
    () => (instrumentOptions ? instrumentOptions.filter((item) => item.checked) : []),
    [instrumentOptions],
  );
  let checkedInstrumentList = {};
  ALL_SERVICES.forEach((service) => {
    const itemsByServiceId = instrumentOptions
      ? instrumentOptions.filter(({ serviceId }) => serviceId === service)
      : [];
    checkedInstrumentList = {
      ...checkedInstrumentList,
      [service]: itemsByServiceId,
    };
  });
  const isAllInstrumentSelected = ALL_SERVICES.every((serviceId) => {
    if ((filteringAssetType !== serviceId && !allAssetsSelected) || accountInfo[serviceId].isNotAvailable) return true;
    if (checkedInstrumentList[serviceId]?.every(({ checked }) => checked)) return true;
    return false;
  });

  let instrumentFilter = {};

  if (isAllInstrumentSelected) {
    instrumentFilter = {
      label: ALL_BRAND_OPTION.label,
      value: ALL_BRAND_OPTION.value,
    };
  } else {
    instrumentFilter = {
      label: SELECTED_INSTRUMENT_OPTION,
      value: selectedInstrumentsOptions,
    };
  }

  return instrumentFilter;
};

export const useAPGroupDeleteAbility = () => {
  const {
    activeApCount: apGroupIsActive,
    positionQuantity: hasOpenPositions,
    apList,
  } = useSelector((state) => state.portfolio.selectedApGroupData);
  const hasOpenOrder = useMemo(
    () => apList.some((item) => Number(item.orderStatus) === 3 || Number(item.orderStatus) === 1),
    [apList],
  );

  let errorMessage = null;

  if (apGroupIsActive && hasOpenPositions && hasOpenOrder) {
    errorMessage = AP_GROUP_DELETE_MESSAGES.apGroupMultipleError;
  } else if (apGroupIsActive) {
    errorMessage = AP_GROUP_DELETE_MESSAGES.apGroupIsActive;
  } else if (hasOpenPositions) {
    errorMessage = AP_GROUP_DELETE_MESSAGES.apGroupHasOpenPositions;
  } else if (hasOpenOrder) errorMessage = AP_GROUP_DELETE_MESSAGES.apGroupHasOpenOrders;

  return errorMessage;
};

const hasOpenOrder = (apList) =>
  apList?.some(
    (item) =>
      Number(item.orderStatus) === AP_GROUP_ORDER.STATUS.OPEN_ORDER.ID ||
      Number(item.orderStatus) === AP_GROUP_ORDER.STATUS.FIRST_ORDER.ID,
  );

export const makeTechDeleteAbility = (apLists, status) => {
  const { positionQuantity: hasOpenPositions, apList } = apLists || {};
  if (status === TECH_STATUS.ACTIVE) {
    return TECH_LOGIC_DELETE_MESSAGES.techIsActive;
  }
  if (hasOpenPositions) {
    return TECH_LOGIC_DELETE_MESSAGES.techHasOpenPositions;
  }
  if (hasOpenOrder(apList)) {
    return TECH_LOGIC_DELETE_MESSAGES.techHasOpenOrders;
  }
  return '';
};

export const useTechDeleteAbility = (apLists, status) => {
  const { positionQuantity: hasOpenPositions, apList } = apLists;

  // 注文ステータスが初回新規発注中(1)または新規発注中(3)のものを探索
  const hasOpenOrderMemoized = useMemo(() => hasOpenOrder(apList), [apList]);

  let errorMessage = '';

  if (status === TECH_STATUS.ACTIVE) {
    errorMessage = TECH_LOGIC_DELETE_MESSAGES.techIsActive;
  } else if (hasOpenPositions) {
    errorMessage = TECH_LOGIC_DELETE_MESSAGES.techHasOpenPositions;
  } else if (hasOpenOrderMemoized) errorMessage = TECH_LOGIC_DELETE_MESSAGES.techHasOpenOrders;

  return errorMessage;
};

export function useTotalMarginForModal(isOpen, margin, countValue) {
  return useMemo(() => {
    if (!isOpen || margin == null || countValue == null || countValue === '') {
      return null;
    }
    return margin;
  }, [isOpen, margin, countValue]);
}

export function useBuySellOptionRestrictions(allowBuy, allowSell, currentOption, setOption) {
  useEffect(() => {
    if (currentOption === BUY_SELL_MAIN.SELL.ID && !allowSell && allowBuy) {
      setOption(BUY_SELL_MAIN.BUY.ID);
    }
    if (currentOption === BUY_SELL_MAIN.BUY.ID && !allowBuy && allowSell) {
      setOption(BUY_SELL_MAIN.SELL.ID);
    }
  }, [allowBuy, allowSell, currentOption, setOption]);
}

export function useResetManualTradeQuantity(defaultQuantity, instrumentId, validatorRef) {
  const dispatch = useDispatch();
  const serviceId = useSelector((state) => state.auth.serviceId);

  const defaultQuantityMultiplier = DEFAULT_QUANTITY_MULTIPLIERS[instrumentId];

  const setQuantity = useCallback(
    (value) => {
      batch(() => {
        Object.values(ORDER_TYPES).forEach((type) => {
          dispatch(
            changeCreateOrderValues({
              orderType: type.name,
              inputName: COUNT_INPUT,
              value,
            }),
          );
        });
      });
      if (validatorRef?.current) {
        setTimeout(
          () =>
            validatorRef.current?.validateManualTradeInput({
              inputName: COUNT_INPUT,
              value,
            }),
          0,
        );
      }
    },
    [dispatch, validatorRef],
  );

  useEffect(() => {
    // change quantity only if multiplier is valid
    // otherwise we should keep it as is
    if (defaultQuantityMultiplier) {
      setQuantity(defaultQuantity * defaultQuantityMultiplier);
    }
  }, [defaultQuantity, defaultQuantityMultiplier, setQuantity]);

  // We only set default value once it mounts or if service switched
  useEffect(() => {
    setQuantity(defaultQuantity * (defaultQuantityMultiplier || 1));
  }, [serviceId]); // eslint-disable-line react-hooks/exhaustive-deps
}

/**
 * hook is used on mobile only for closing many positions on Orders screen
 */
export const useSelectOpenPositions = (filter) => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const tableDataRaw = useSelector((state) => state.currencies.positions[serviceId]);
  const positionMetaData = useSelector((state) => state.manualTrade.positionsDataMetaInfo[serviceId]);

  const filteredData = useMemo(
    () =>
      filter
        ? tableDataRaw.filter(filter)
        : tableDataRaw.filter((item) => {
            const filteringByTradeMethod =
              positionMetaData.tradeMethods.length === 0
                ? true
                : positionMetaData.tradeMethods.includes(Number(item.tradeMethod));
            const filteringByInstrumentId = positionMetaData.instrumentId
              ? removeSuffix(item.instrumentId) === positionMetaData.instrumentId
              : true;
            const filteringBySide = positionMetaData.side ? Number(item.side) === positionMetaData.side : true;

            return filteringByTradeMethod && filteringByInstrumentId && filteringBySide;
          }),
    [positionMetaData.instrumentId, tableDataRaw, positionMetaData.tradeMethods, positionMetaData.side, filter],
  );

  const [selectedPositions, setSelectedPositions] = useState([]);

  const isAllSelected = filteredData.length === selectedPositions.length;

  const toggleSelectAll = () => {
    let updatedPositions = [];
    if (!isAllSelected) updatedPositions = filteredData.map((el) => ({ positionId: el.positionId }));
    setSelectedPositions(updatedPositions);
  };

  const clearSelectedPositions = useCallback(() => {
    setSelectedPositions([]);
  }, []);

  useEffect(() => {
    // unselect all positions every time filteredData is changed
    if (filteredData.length) clearSelectedPositions();
  }, [clearSelectedPositions, filteredData.length]);

  const toggleCheckbox = useCallback(
    (positionId) => {
      const isAlreadyAdded = Boolean(selectedPositions.find((el) => el.positionId === positionId));

      let updatedPositions = [...selectedPositions];
      if (isAlreadyAdded) updatedPositions = updatedPositions.filter((el) => el.positionId !== positionId);
      else updatedPositions.push({ positionId });

      setSelectedPositions(updatedPositions);
    },
    [selectedPositions],
  );

  return {
    selectedPositions,
    isAllSelected,
    toggleCheckbox,
    toggleSelectAll,
    clearSelectedPositions,
  };
};

/**
 * hook is used on mobile only for closing many positions on Orders screen
 */
export const useSelectOpenPositionsSummary = () => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const positionData = useSelector((state) => state.currencies.positions[serviceId]);

  const [selectedPositions, setSelectedPositions] = useState([]);
  const [selectedInstrumentIds, setSelectedInstrumentIds] = useState([]);

  const isAllSelected = positionData.length === selectedPositions.length;
  const toggleSelectAll = () => {
    let updatedPositions = [];
    let updateInstrumentIds = [];

    if (!isAllSelected) {
      updatedPositions = positionData.map((el) => ({
        positionId: el.positionId,
        instrumentId: el.instrumentId,
        side: el.side,
        unrealizedSwapPl: el.unrealizedSwapPl,
      }));
      updateInstrumentIds = Array.from(
        new Map(
          positionData.map((p) => [
            `${p.instrumentId}${p.side}`,
            { instrumentId: p.instrumentId, side: p.side, unrealizedSwapPl: p.unrealizedSwapPl },
          ]),
        ).values(),
      );
    }
    setSelectedPositions(updatedPositions);
    setSelectedInstrumentIds([...updateInstrumentIds]);
  };

  const clearSelectedPositions = useCallback(() => {
    setSelectedPositions([]);
    setSelectedInstrumentIds([]);
  }, []);

  useEffect(() => {
    // unselect all positions every time filteredData is changed
    if (positionData.length) clearSelectedPositions();
  }, [clearSelectedPositions, positionData.length]);

  const toggleCheckbox = useCallback(
    (instrumentId, side) => {
      const isAlreadyAdded = Boolean(
        selectedInstrumentIds.filter((el) => el.instrumentId === instrumentId && el.side === side).length,
      );

      let updatedPositions = [...selectedPositions];
      let updateInstrumentIds = [...selectedInstrumentIds];

      if (isAlreadyAdded) {
        updatedPositions = updatedPositions.filter((el) => !(el.instrumentId === instrumentId && el.side === side));
        updateInstrumentIds = updateInstrumentIds.filter(
          (el) => !(el.instrumentId === instrumentId && el.side === side),
        );
      } else {
        const positionIds = positionData
          .filter((p) => p.instrumentId === instrumentId && p.side === side)
          .map((m) => ({
            positionId: m.positionId,
            instrumentId: m.instrumentId,
            side: m.side,
            unrealizedSwapPl: m.unrealizedSwapPl,
          }));
        updatedPositions.push(...positionIds);
        updateInstrumentIds.push({ instrumentId, side });
      }

      setSelectedPositions(updatedPositions);
      setSelectedInstrumentIds(updateInstrumentIds);
    },
    [selectedPositions, selectedInstrumentIds, positionData],
  );

  return {
    selectedPositions,
    selectedInstrumentIds,
    isAllSelected,
    toggleCheckbox,
    toggleSelectAll,
    clearSelectedPositions,
  };
};

/**
 * hook is used in close one/many positions flow
 * check is performed when user tries to close one position
 * opens error modal if fails
 */
export const useCheckOnPositionIsBeingDeleted = () => {
  const deletingPositions = useSelector((state) => state.manualTrade.deletingPositions);

  const dispatch = useDispatch();

  return useCallback(
    ({ positionId, successCallback, isToggleAll = false, tableData }) => {
      let currentPosition = null;
      let isForbiddenToChange = false;

      // this check is used when we click on "toggle all" checkbox in positions table
      // in any other cases "else" block is used
      if (isToggleAll) {
        const intersectedList = intersectionBy(deletingPositions, tableData, 'positionId');
        if (intersectedList.length) isForbiddenToChange = true;
      } else {
        currentPosition = deletingPositions.find((el) => el.positionId === positionId);

        // check if user presses position that has been sent to deletion two minutes ago or less
        if (currentPosition) {
          isForbiddenToChange =
            currentPosition && Date.now() - currentPosition.deletionStartedAt <= POSITION_DELETION_EXPIRE_TIME;
        }
      }

      if (isForbiddenToChange) {
        dispatch(
          openErrorInfoModal({
            title: 'エラー',
            message: POSITIONS_ARE_STILL_DELETING,
          }),
        );
      } else {
        successCallback();
      }
    },
    [deletingPositions, dispatch],
  );
};

export const useCheckOnInstrumentIdPositionIsBeingDeleted = () => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const positionData = useSelector((state) => state.currencies.positions[serviceId]);
  const deletingPositions = useSelector((state) => state.manualTrade.deletingPositions);

  const dispatch = useDispatch();

  return useCallback(
    ({ instrumentId, side, successCallback }) => {
      let targetPosition = null;
      let isForbiddenToChange = false;

      const holdPositionIds = positionData
        .filter((p) => p.instrumentId === instrumentId && p.side === side)
        .map((m) => m.positionId);
      // this check is used when we click on "toggle all" checkbox in positions table
      // in any other cases "else" block is used

      targetPosition = deletingPositions.filter((el) => holdPositionIds.includes(el?.positionId));

      // check if user presses position that has been sent to deletion two minutes ago or less
      isForbiddenToChange = targetPosition.filter(
        (position) => Date.now() - position.deletionStartedAt <= POSITION_DELETION_EXPIRE_TIME,
      );

      if (isForbiddenToChange.length) {
        dispatch(
          openErrorInfoModal({
            title: 'エラー',
            message: POSITIONS_ARE_STILL_DELETING,
          }),
        );
      } else {
        successCallback();
      }
    },
    [deletingPositions, positionData, dispatch],
  );
};

export const useCheckOnPositionSummaryIsBeingDeleted = () => {
  const deletingPositions = useSelector((state) => state.manualTrade.deletingPositions);

  const dispatch = useDispatch();

  return useCallback(
    ({ positionIds, successCallback, isToggleAll = false, tableData }) => {
      let isForbiddenToChange = false;

      // this check is used when we click on "toggle all" checkbox in positions table
      // in any other cases "else" block is used
      if (isToggleAll) {
        const tablePositionIds = tableData.map(({ positionIds: itemPositionIds }) => itemPositionIds);
        isForbiddenToChange = deletingPositions.some(({ positionId }) => tablePositionIds.includes(positionId));
      } else {
        isForbiddenToChange = positionIds.some((positionId) => {
          const currentPosition = deletingPositions.find((el) => el === positionId);

          // check if user presses position that has been sent to deletion two minutes ago or less
          return currentPosition && Date.now() - currentPosition.deletionStartedAt <= POSITION_DELETION_EXPIRE_TIME;
        });
      }

      if (isForbiddenToChange) {
        dispatch(
          openErrorInfoModal({
            title: 'エラー',
            message: POSITIONS_ARE_STILL_DELETING,
          }),
        );
      } else {
        successCallback();
      }
    },
    [deletingPositions, dispatch],
  );
};

/**
 * hook is used in close one/many positions flow
 * returns all positions that is still being deleted
 */
export const useGetStillDeletingPositions = () => {
  const deletingPositions = useSelector((state) => state.manualTrade.deletingPositions);

  return useCallback(
    () => deletingPositions.filter((el) => Date.now() - el.deletionStartedAt <= POSITION_DELETION_EXPIRE_TIME),
    [deletingPositions],
  );
};

export function useUuid() {
  const ref = useRef(null);

  if (ref.current === null) ref.current = uuid();

  return ref.current;
}

export {
  useReportsLogic,
  useGetReportFile,
  useReportDailySettings,
  useReportMonthlySettings,
  useReportQuarterSettings,
  useReportsServiceChange,
} from './reportsLogic';
